import dayjs from 'dayjs';

export function getDaysArray(startDate: string, endDate: string): string[] {
  const dates = [];
  let currentWeekStart = dayjs(startDate);
  const endOfWeek = dayjs(endDate);

  while (
    currentWeekStart.isBefore(endOfWeek) ||
    currentWeekStart.isSame(endOfWeek)
  ) {
    dates.push(currentWeekStart.format('YYYY-MM-DD'));
    currentWeekStart = currentWeekStart.add(1, 'day');
  }

  return dates;
}
