import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  ConfigurationContext,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

export const StationBlocksTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      stationId: number;
      companyId: number;
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { stationId, companyId, filterColumns, ...rest } = props;
    const { hasura } = useContext(ConfigurationContext);
    const [sortValues, setSortValues] = useState<number[] | null>(null);

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const fetchPriorities = useCallback(async () => {
      const items = await hasura.request({
        type: 'query',
        source: 'spotBlock',
        selection: 'priority',
        where: {
          stationId: { _eq: stationId },
          companyId: { _eq: companyId },
        },
      });

      setSortValues(items.map((x: any) => x.priority));
    }, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            headerName: 'Priority',
            field: 'priority',
            selector: 'priority',
            type: 'sort',
            sortKey: 'priority',
            rowsSortValues: sortValues,
            width: 130,
            onSortChange: async (direction, row, targetRowSort) => {
              const source = 'SpotBlock';
              const { id } = row;

              await hasura.request({
                type: 'custom',
                query: `
                mutation ChangeSort($where1: ${source}BoolExp!, $set1: ${source}SetInput!, $where2: ${source}BoolExp!, $set2: ${source}SetInput!) {
                  result2: update${source}(where: $where2, _set: $set2) {
                    affected_rows
                  }
                  result1: update${source}(where: $where1, _set: $set1) {
                    affected_rows
                  }
                }
              `,
                variables: {
                  where1: {
                    id: { _eq: id },
                    stationId: { _eq: row.stationId },
                  },
                  set1: { priority: targetRowSort },
                  where2: {
                    priority: { _eq: targetRowSort },
                    stationId: { _eq: row.stationId },
                  },
                  set2: { priority: row.priority },
                },
              });

              tableRef.current?.reload();
            },
          },
          {
            field: 'minute',
            headerName: 'Minute',
            flex: 1,
            sortable: false,
            valueFormatter({ value }) {
              return 'XX:' + value?.toString().padStart(2, '0');
            },
          },
        ]),
      [filterColumns, sortValues],
    );

    useEffect(() => {
      fetchPriorities();
    }, []);

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="spotBlock"
        columns={columns}
        persistStateMode="query"
        sortBy={{ field: 'priority', sort: 'asc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        deletable={{
          onDeleted: (row) => {
            setSortValues(sortValues!.filter((x) => x !== row.priority));
          },
        }}
        selectProps={{
          filter: {
            stationId: { _eq: stationId },
            companyId: { _eq: companyId },
          },
          onSelection(selections) {
            return ['stationId', ...selections];
          },
        }}
        formTitle={(isNew) => (isNew ? 'New block' : 'Edit block')}
        formDialogProps={{
          ...rest.formDialogProps,
          maxWidth: 'xs',
          formSubmitterProps: {
            preSubmit(item) {
              return {
                stationId,
                companyId,
                ...item,
              };
            },
            onSubmit() {
              fetchPriorities();
            },
          },
        }}
      >
        <FormInput name="minute" label="Minute" type="number" required />
      </DataTableEx>
    );
  },
);
