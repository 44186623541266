import { TablePageLayout, DataTableExRef } from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useContext, useRef } from 'react';

import { CompanyUsersTable } from 'components/CompanyUsersTable';
import { UserContext } from 'contexts/UserContext';
import { HideStationSelector } from 'layouts/HideStationSelector';

export function Users() {
  const { user } = useContext(UserContext);

  const tableRef = useRef<DataTableExRef>(null);

  return (
    <HideStationSelector>
      <TablePageLayout
        title="Users"
        actionContent={
          <Button
            sx={{ ml: 5 }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add user
          </Button>
        }
      >
        <CompanyUsersTable
          id="users-table"
          ref={tableRef}
          companyId={user.companyId}
          selectProps={{
            filter: {
              companyId: { _eq: user.companyId },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name or email',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [
                { firstName: { _ilike: `%${str}%` } },
                { lastName: { _ilike: `%${str}%` } },
                { email: { _ilike: `%${str}%` } },
              ]),
            }),
          }}
          formDialogProps={{
            formProps: {
              defaultValues: {
                companyId: user.companyId,
              },
            },
          }}
        />
      </TablePageLayout>
    </HideStationSelector>
  );
}
