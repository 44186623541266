import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { Calendar } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { GridNameColumn } from 'components/GridNameColumn';
import { AppStateContext } from 'contexts/AppStateContext';
import { UserContext } from 'contexts/UserContext';

export function Spots() {
  const { selectedStationId } = useContext(AppStateContext);
  const { user } = useContext(UserContext);
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell({ row }) {
          return <GridNameColumn url={`/spots/${row.id}`} text={row.name} />;
        },
      },
      {
        field: 'wave { *name* }',
        headerName: 'Wave',
        type: 'relationship',
        flex: 1,
      },
      {
        field: 'campaign { *name* }',
        headerName: 'Campaign',
        type: 'relationship',
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
      {
        field: 'planning',
        headerName: ' ',
        selector: false,
        disableColumnMenu: true,
        sortable: false,
        width: 100,
        renderCell({ row }) {
          return (
            <Button
              size="small"
              startIcon={<Calendar />}
              href={`/spots/${row.id}/overview`}
            >
              Planning
            </Button>
          );
        },
      },
    ],
    [],
  );

  return (
    <TablePageLayout title="Spots">
      <DataTableEx
        id="spots-table"
        ref={tableRef}
        source="waveSpot"
        columns={columns}
        automaticallyOpenEditPage={false}
        editPageUrl="/spots"
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name, wave or campaign',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              {
                _or: [
                  { name: { _ilike: `%${str}%` } },
                  { wave: { name: { _ilike: `%${str}%` } } },
                  { campaign: { name: { _ilike: `%${str}%` } } },
                ],
              },
            ]),
          }),
        }}
        selectProps={{
          filter: {
            companyId: { _eq: user.companyId },
            stationId: { _eq: selectedStationId },
          },
        }}
      />
    </TablePageLayout>
  );
}
