import { FormGetter } from '@kirz/mui-admin';
import { Checkbox } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useDaypartCalendar } from './DaypartCalendarContext';
import { getSlotId } from './utils';

type Props = {
  block: { id: number; minute: number; priority: number };
};

export function BlockCheckbox({ block }: Props) {
  const { setValue } = useFormContext();
  const { copiedBlocksAtom, selectedPositionAtom } = useDaypartCalendar();
  const copiedBlocks = useAtomValue(copiedBlocksAtom);
  const selectedPosition = useAtomValue(selectedPositionAtom);
  const slotId = getSlotId(
    selectedPosition.weekDay,
    selectedPosition.hour,
    block.id,
  );

  return (
    <FormGetter
      key={slotId}
      names={[slotId]}
      render={({ [slotId]: value }) => {
        return (
          <Checkbox
            disabled={!!copiedBlocks}
            checked={value}
            onClick={async () => {
              setValue(slotId, !value, {
                shouldDirty: true,
              });
            }}
          />
        );
      }}
    />
  );
}
