import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { GridNameColumn } from './GridNameColumn';

export const CompanyStationsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
          { field: 'createdAt', headerName: 'Created at', type: 'date' },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="station"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'id', sort: 'desc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        formTitle={(isNew) => (isNew ? 'New station' : 'Edit station')}
        formDialogProps={{
          ...rest.formDialogProps,
          formSubmitterProps: {
            ...rest.formDialogProps?.formSubmitterProps,
            preSubmit(item) {
              return {
                companyId:
                  rest.formDialogProps?.formProps?.defaultValues?.companyId,
                ...item,
              };
            },
          },
        }}
      >
        <FormInput name="name" label="Name" required />
      </DataTableEx>
    );
  },
);
