import { FormGetter } from '@kirz/mui-admin';
import { useAtomValue } from 'jotai';
import React from 'react';

import { getWeekDay } from 'utils/getWeekDay';

import { useSpotOverview } from './SpotOverviewContext';
import { getSlotId, hours } from './utils';

type Props = {
  date: string;
};

export function WeekCounter({ date }: Props) {
  const { spot, daypartSlotsAtom } = useSpotOverview();
  const daypartSlots = useAtomValue(daypartSlotsAtom);

  const weekDay = getWeekDay(date);

  const slotIds = hours.flatMap((hour) => {
    return daypartSlots
      .filter((x) => x.weekDay === weekDay && x.hour === hour)
      .map((daypartSlot) =>
        getSlotId(
          spot.daypart.id,
          date,
          daypartSlot.daypartSpotId,
          daypartSlot.blockId,
        ),
      );
  });

  return (
    <FormGetter
      names={slotIds}
      render={(values) => {
        const selectedCount = Object.values(values).filter((x) => !!x).length;

        return selectedCount;
      }}
    />
  );
}
