import { NotificationsContext } from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { Broom } from 'mdi-material-ui';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

export function ResetCalendarButton() {
  const { getValues, setValue } = useFormContext();
  const { showConfirm, showAlert } = useContext(NotificationsContext);

  const handleReset = async () => {
    const result = await showConfirm({
      title: 'Reset planning?',
      text: 'This action will desect all slots',
      accept: 'Confirm',
      cancel: 'Cancel',
    });

    if (!result) {
      return;
    }

    const values = getValues();
    const slotIds = Object.keys(values)
      .filter((x) => x.startsWith('slot_'))
      .filter((x) => values[x]);
    slotIds.forEach((slotId) => {
      setValue(slotId, false, { shouldDirty: true });
    });

    showAlert('Planning reset');
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleReset}
      size="small"
      startIcon={<Broom />}
    >
      Reset planning
    </Button>
  );
}
