import {
  AutocompleteInput,
  ConfigurationContext,
  FormGetter,
  FormInput,
  FormPageLayout,
  HiddenInput,
  SubmitButton,
} from '@kirz/mui-admin';
import { Save } from '@mui/icons-material';
import { Box, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CalendarMultiselectOutline } from 'mdi-material-ui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { HideStationSelector } from 'layouts/HideStationSelector';

import { DaypartCalendar } from './sections/DaypartCalendar';
import { DaypartCalendarProvider } from './sections/DaypartCalendarContext';
import { FetchedDaypart } from './sections/types';
import { getSlotId, splitSlotId } from './sections/utils';

export function Daypart() {
  const originalSlotsRef = useRef<Record<string, boolean>>();
  const fetchedItemRef = useRef<any>();
  const routeParams = useParams() as Record<string, string>;
  const { hasura } = useContext(ConfigurationContext);
  const daypartId = parseInt(routeParams.id, 10);
  const [blockIds, setBlockIds] = useState<number[] | null>(null);

  useEffect(() => {
    (async () => {
      const [daypart] = await hasura.request({
        type: 'query',
        source: 'daypart',
        selection: `station { blocks { id } }`,
        where: {
          id: { _eq: daypartId },
        },
        limit: 1,
      });

      setBlockIds(daypart.station.blocks.map((x: any) => x.id));
    })();
  }, [daypartId]);

  if (!blockIds) {
    return (
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const weekDays = [...new Array(7).keys()];
  const hours = [...new Array(24).keys()];

  const allSlotIds = weekDays.flatMap((weekDay) =>
    hours.flatMap((hour) =>
      blockIds.map((spotBlockId) => getSlotId(weekDay, hour, spotBlockId)),
    ),
  );

  return (
    <HideStationSelector>
      <FormPageLayout
        hideSaveButton
        source="daypart"
        breadcrumbs={[
          {
            text: 'Dayparts',
            icon: CalendarMultiselectOutline,
            href: '/dayparts',
          },
          (item) => ({
            text: `${item.name}`,
            copyOnClick: true,
          }),
        ]}
        breadcrumbsDeps={['name']}
        defaultRoute="/dayparts"
        formFetcherProps={{
          onSelection(selections) {
            return [
              ...selections,
              `
                station {
                  blocks (orderBy:{minute:ASC}) {
                    id
                    minute
                    priority
                  }
                }
                spots {
                  id
                  weekDay
                  hour
                  spotBlockId
                }
              `,
            ];
          },
          onFetch(item: FetchedDaypart) {
            const blockIds = item.station.blocks.map((x) => x.id);

            originalSlotsRef.current = {
              ...Object.fromEntries(
                weekDays.flatMap((weekDay) =>
                  hours.flatMap((hour) =>
                    blockIds.map((spotBlockId) => [
                      getSlotId(weekDay, hour, spotBlockId),
                      false,
                    ]),
                  ),
                ),
              ),
              ...Object.fromEntries(
                item.spots.map((spot) => [
                  getSlotId(spot.weekDay, spot.hour, spot.spotBlockId),
                  true,
                ]),
              ),
            };

            fetchedItemRef.current = {
              ...item,
              ...originalSlotsRef.current,
            };

            return fetchedItemRef.current;
          },
        }}
        formSubmitterProps={{
          mode: 'none',
          async preSubmit(item) {
            const clearObject = (obj: Record<string, any>) => {
              return Object.fromEntries(
                Object.entries(obj).filter(([key, value]) =>
                  key.startsWith('slot_'),
                ),
              );
            };

            const originalSlots = clearObject(originalSlotsRef.current!);
            const newSlots = clearObject(item);

            const addedSlots: string[] = [];
            const removedSlots: string[] = [];

            const allKeys = new Set([
              ...Object.keys(originalSlots),
              ...Object.keys(newSlots),
            ]);

            originalSlotsRef.current = newSlots;

            allKeys.forEach((key) => {
              const val1 = originalSlots[key] || false;
              const val2 = newSlots[key] || false;

              if (val1 === false && val2 === true) {
                addedSlots.push(key);
              } else if (val1 === true && val2 === false) {
                removedSlots.push(key);
              }
            });

            const deleteWhere = removedSlots.length && {
              _or: removedSlots.map((id) => {
                const { hour, weekDay, spotBlockId } = splitSlotId(id);

                return {
                  stationId: { _eq: item.stationId },
                  daypartId: { _eq: daypartId },
                  spotBlockId: { _eq: spotBlockId },
                  hour: { _eq: hour },
                  weekDay: { _eq: weekDay },
                };
              }),
            };

            await Promise.all([
              ...(addedSlots.length
                ? [
                    hasura.request({
                      source: 'daypartSpot',
                      type: 'mutation',
                      action: 'insert',
                      items: addedSlots.map((id) => {
                        const { hour, weekDay, spotBlockId } = splitSlotId(id);

                        return {
                          stationId: item.stationId,
                          spotBlockId,
                          hour,
                          daypartId,
                          weekDay,
                        };
                      }),
                    }),
                  ]
                : []),
              ...(deleteWhere
                ? [
                    hasura.request({
                      action: 'update',
                      source: 'daypartSpot',
                      type: 'mutation',
                      where: deleteWhere,
                      set: {
                        isRemoved: true,
                      },
                    }),
                  ]
                : []),
              hasura.request({
                action: 'update',
                source: 'daypart',
                type: 'mutation',
                where: {
                  id: { _eq: daypartId },
                },
                set: {
                  name: item.name,
                },
              }),
            ]);

            return item;
          },
        }}
      >
        <Grid container alignItems="flex-start">
          <FormInput name="name" label="Name" required md={4} />
          <AutocompleteInput
            name="stationId"
            source="station"
            mode="hasura"
            selection="id name"
            label="Station"
            itemText="name"
            itemValue="id"
            required
            md={4}
            readOnly
          />

          {allSlotIds.map((slotId) => (
            <HiddenInput
              key={slotId}
              name={slotId}
              formFetcherValueResolver={null}
              formSubmitterValueResolver={null}
            />
          ))}

          <FormGetter
            names={['stationId', 'id', 'station', 'spots']}
            render={(values) => {
              if (!values.id && fetchedItemRef.current) {
                values = {
                  ...fetchedItemRef.current,
                  id: daypartId,
                };
              }

              if (!values.id) {
                return null;
              }
              console.log(values);
              return (
                <DaypartCalendarProvider daypart={values}>
                  <Grid xs={12} sx={{ pt: 5 }}>
                    <DaypartCalendar />
                  </Grid>
                  <Grid
                    xs={12}
                    md={8}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      mt: -10,
                      pr: 10,
                    }}
                  >
                    <SubmitButton
                      grid={false}
                      variant="contained"
                      activateOnDirty
                      sx={{ ml: 'auto' }}
                      startIcon={<Save />}
                    >
                      Save changes
                    </SubmitButton>
                  </Grid>
                </DaypartCalendarProvider>
              );
            }}
          />
        </Grid>
      </FormPageLayout>
    </HideStationSelector>
  );
}
