import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useMemo, useRef } from 'react';

import { GridNameColumn } from 'components/GridNameColumn';
import { HideStationSelector } from 'layouts/HideStationSelector';

export function Companies() {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'name',
        headerName: 'Name',
        width: 200,
        renderCell({ row }) {
          return (
            <GridNameColumn url={`/companies/${row.id}`} text={row.name} />
          );
        },
      },
      {
        field: 'stations',
        selector: 'stations (orderBy: { createdAt: DESC } ) { name }',
        headerName: 'Stations',
        sortable: false,
        flex: 1,
        type: 'string',
        valueGetter({ row }) {
          if (!row.stations?.length) {
            return '—';
          }

          return row.stations.map((x: any) => x.name).join(', ');
        },
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <HideStationSelector>
      <TablePageLayout
        title="Companies"
        actionContent={
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<TextBoxPlusOutline />}
            onClick={async () => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add company
          </Button>
        }
      >
        <DataTableEx
          id="companies-table"
          ref={tableRef}
          source="company"
          columns={columns}
          editPageUrl="/companies"
          deletable={false}
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? 'New company' : 'Edit channel')}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
        >
          <FormInput name="name" label="Name" required />
        </DataTableEx>
      </TablePageLayout>
    </HideStationSelector>
  );
}
