import { Box, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import { useDaypartCalendar } from './DaypartCalendarContext';
import { WeekDays } from './utils';

export function BlocksTitle() {
  const { selectedPositionAtom } = useDaypartCalendar();
  const selectedPosition = useAtomValue(selectedPositionAtom);

  return (
    <Typography variant="caption" sx={{ width: 110, fontWeight: '500', mt: 1 }}>
      Blocks
      <Box component="span" sx={{ opacity: 0.8, ml: 1 }}>
        [{WeekDays[selectedPosition.weekDay].substring(0, 3)}
        {', '}
        {selectedPosition.hour.toString().padStart(2, '0')}]
      </Box>
    </Typography>
  );
}
