import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  SelectInput,
  AutocompleteInput,
  HiddenInput,
  ConfigurationContext,
  ConditionalInput,
} from '@kirz/mui-admin';
import { Typography } from '@mui/material';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { UserRole, UserRoles } from 'constants/permissions';
import { AppStateContext } from 'contexts/AppStateContext';

export const CompanyUsersTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      companyId: number;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { companyId, filterColumns, ...rest } = props;
    const { stations: allStations } = useContext(AppStateContext);
    const { hasura } = useContext(ConfigurationContext);

    const stations = allStations.filter((x) => x.companyId === companyId);
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'firstName',
            headerName: 'Name',
            selector: 'firstName lastName',
            flex: 1,
            valueGetter: ({ row }) =>
              `${row.firstName ?? ''} ${row.lastName ?? ''}`.trim(),
          },
          {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            type: 'email',
          },
          {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            type: 'select',
            items: UserRoles,
          },
          {
            field: 'stations',
            headerName: 'Stations',
            selector: 'role stations { stationId }',
            flex: 1,
            renderCell({ value, row }) {
              const ids =
                row.role === UserRole.ADMIN
                  ? stations.map((x) => ({ stationId: x.id }))
                  : value;

              if (!ids?.length) {
                return '—';
              }

              return (
                <Typography variant="body2">
                  {ids
                    .map(
                      ({ stationId }: any) =>
                        stations.find((x) => x.id === stationId)!.name,
                    )
                    .join(', ')}
                </Typography>
              );
            },
          },
          { field: 'createdAt', headerName: 'Created at', type: 'date' },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="user"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'createdAt', sort: 'desc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        formTitle={(isNew) => (isNew ? 'New user' : 'Edit user')}
        formDialogProps={{
          ...rest.formDialogProps,
          maxWidth: 'sm',
          formFetcherProps: {
            onSelection(selections) {
              return [
                'id',
                ...selections.map((x) =>
                  x === 'stations' ? 'stations { stationId companyId }' : x,
                ),
              ];
            },
            onFetch({ stations, ...item }) {
              const ids = (stations ?? []).map((x: any) => x.stationId);

              return {
                ...item,
                stations: ids,
                initialStations: ids,
              };
            },
          },
          formSubmitterProps: {
            async preSubmit({
              id: userId,
              stations,
              initialStations,
              ...item
            }) {
              if (item.role === UserRole.ADMIN) {
                return {
                  companyId,
                  ...item,
                };
              }

              if (!userId) {
                return {
                  companyId,
                  stations: {
                    data: stations.map((stationId: number) => ({
                      stationId,
                      companyId,
                    })),
                  },
                  ...item,
                };
              }

              const removedStations = initialStations.filter(
                (id: any) => !stations.includes(id),
              );

              const addedStations = stations.filter(
                (id: any) => !initialStations.includes(id),
              );

              await hasura.request({
                action: 'update',
                source: 'userStation',
                where: {
                  userId: { _eq: userId },
                  stationId: { _in: removedStations },
                },
                type: 'mutation',
                set: {
                  isRemoved: true,
                },
              });

              await hasura.request({
                action: 'insert',
                source: 'userStation',
                type: 'mutation',
                items: addedStations.map((stationId: number) => ({
                  stationId,
                  userId,
                  companyId,
                })),
              });

              return {
                companyId,
                ...item,
              };
            },
            onSubmit() {
              tableRef.current?.reload();
            },
          },
        }}
      >
        <HiddenInput name="id" />
        <HiddenInput name="initialStations" formFetcherValueResolver={null} />

        <FormInput name="firstName" label="First name" md={6} required />
        <FormInput name="lastName" label="Last name" md={6} />
        <FormInput name="email" label="E-mail" type="email" md={6} required />
        <SelectInput
          name="role"
          label="Role"
          items={UserRoles}
          md={6}
          required
        />

        <ConditionalInput
          deps={['role']}
          variants={[
            {
              if: (y) => y.role === 'user',
              input: (y) => (
                <AutocompleteInput
                  name="stations"
                  label="Stations"
                  multiple
                  options={stations.map((x) => ({ value: x.id, text: x.name }))}
                />
              ),
            },
          ]}
        />
      </DataTableEx>
    );
  },
);
