import {
  ConfigurationProvider as MuiAdminConfigurationProvider,
  NavigationContextProvider,
} from '@kirz/mui-admin';
import React, { useContext, useEffect } from 'react';
import {
  Routes as DomRoutes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom';

import { RouteGuard } from 'components/RouteGuard';
import { SplashLoader } from 'components/SplashLoader';
import { Configuration } from 'constants/configuration';
import { UserContext } from 'contexts/UserContext';
import { DefaultLayout } from 'layouts/DefaultLayout';
import { Blocks } from 'pages/Blocks';
import { Campaign } from 'pages/Campaign';
import { Campaigns } from 'pages/Campaigns';
import { CampaignsOverview } from 'pages/CampaignsOverview';
import { Companies } from 'pages/Companies';
import { Company } from 'pages/Company';
import { Daypart } from 'pages/Daypart';
import { Dayparts } from 'pages/Dayparts';
import { Login } from 'pages/Login';
import { Spot } from 'pages/Spot';
import { SpotOverview } from 'pages/SpotOverview';
import { Spots } from 'pages/Spots';
import { StationSettings } from 'pages/StationSettings';
import { Users } from 'pages/Users';
import { Wave } from 'pages/Wave';
import { WaveOverview } from 'pages/WaveOverview';
import { Waves } from 'pages/Waves';

export default function App() {
  const { isUserLoading, user, hasPermission } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoading || user) {
      return;
    }

    navigate('/login');
  }, [navigate, isUserLoading, user]);

  const routes = [
    ...(!user?.isSuperAdmin
      ? [
          {
            path: 'campaigns',
            children: (
              <>
                <Route index element={<Campaigns />} />
                <Route path=":id" element={<Campaign />} />
                <Route path=":id/overview" element={<CampaignsOverview />} />
              </>
            ),
          },
          {
            path: 'waves',
            children: (
              <>
                <Route index element={<Waves />} />
                <Route path=":id" element={<Wave />} />
                <Route path=":id/overview" element={<WaveOverview />} />
              </>
            ),
          },
          {
            path: 'spots',
            children: (
              <>
                <Route index element={<Spots />} />
                <Route path=":id" element={<Spot />} />
                <Route path=":id/overview" element={<SpotOverview />} />
              </>
            ),
          },
        ]
      : []),
    ...(user?.isSuperAdmin
      ? [
          {
            path: 'companies',
            children: (
              <>
                <Route index element={<Companies />} />
                <Route path=":id" element={<Company />} />
              </>
            ),
          },
        ]
      : [
          ...(user?.role === 'admin'
            ? [
                {
                  path: 'users',
                  element: <Users />,
                },
                {
                  path: 'stations',
                  element: <StationSettings />,
                },
                {
                  path: 'blocks',
                  element: <Blocks />,
                },
              ]
            : []),
          {
            path: 'dayparts',
            children: (
              <>
                <Route index element={<Dayparts />} />
                <Route path=":id" element={<Daypart />} />
              </>
            ),
          },
        ]),
  ] as {
    path: string;
    permission?: string | string[];
    children?: React.ReactNode;
    element?: React.ReactNode;
  }[];

  const defaultRoute =
    routes.find(
      (x) =>
        !x.permission ||
        (typeof x.permission === 'string'
          ? hasPermission(x.permission)
          : !!x.permission.find((y) => hasPermission(y))),
    )?.path || '/';

  return (
    <MuiAdminConfigurationProvider {...Configuration().muiAdmin}>
      <NavigationContextProvider>
        <DomRoutes>
          <Route path="login" element={<Login />} />
          {user && (
            <>
              <Route path="/" element={<DefaultLayout />}>
                {routes.map((route) => {
                  const content = route.children || (
                    <Route index element={route.element} />
                  );

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<RouteGuard permission={route.permission} />}
                    >
                      {content}
                    </Route>
                  );
                })}
                <Route index element={<Navigate to={defaultRoute} />} />
              </Route>
              <Route path="*" element={<Navigate to={defaultRoute} />} />
            </>
          )}
        </DomRoutes>
        <SplashLoader visible={isUserLoading} />
      </NavigationContextProvider>
    </MuiAdminConfigurationProvider>
  );
}
