import { FormGetter, FormPageLayout } from '@kirz/mui-admin';
import { ClipboardListOutline, Waves as WavesIcon } from 'mdi-material-ui';
import React from 'react';
import { useParams } from 'react-router-dom';

import { SpotsCalendar } from 'components/SpotsCalendar';
import { HideStationSelector } from 'layouts/HideStationSelector';

type FetchedItem = {
  id: number;
  spots: {
    id: number;
    name: string;
    daypartId: number;
    slots: {
      date: string;
      daypartId: number;
      spot: { hour: number };
      block: { minute: number };
    }[];
  }[];
};

export function WaveOverview() {
  return (
    <HideStationSelector>
      <FormPageLayout
        source="wave"
        breadcrumbs={[
          (item) => ({
            icon: ClipboardListOutline,
            text: item.campaign?.name,
            href: `/campaigns/${item.campaign?.id}`,
          }),
          (item) => ({
            text: 'Waves',
            icon: WavesIcon,
            href: `/campaigns/${item.campaign?.id}`,
          }),
          (item) => ({
            text: `${item.name ?? ''}`,
            href: `/waves/${item?.id}`,
          }),
          (item) => ({
            text: `Calendar`,
          }),
        ]}
        breadcrumbsDeps={['name', 'campaign']}
        defaultRoute="/waves"
        formFetcherProps={{
          onSelection() {
            return [
              'id',
              'name',
              'campaign { id name stationId }',
              `spots {
                id
                name
                daypartId
                slots {
                  date
                  daypartId
                  spot {
                    hour
                  }
                  block {
                    minute
                  }
                }
              }`,
            ];
          },
        }}
      >
        <FormGetter
          names={['campaign', 'id', 'spots']}
          render={(values: FetchedItem) => {
            if (!values.id) {
              return null;
            }

            return (
              <SpotsCalendar
                slots={values.spots.flatMap((spot, spotIndex) => {
                  return spot.slots
                    .filter((x) => x.daypartId === spot.daypartId)
                    .map((slot, idx) => ({
                      date: slot.date,
                      hour: slot.spot.hour,
                      minute: slot.block.minute,
                      name: spot.name,
                      spotId: spot.id,
                      spotIndex,
                    }));
                })}
              />
            );
          }}
        />
      </FormPageLayout>
    </HideStationSelector>
  );
}
