import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BlockCheckbox } from './BlockCheckbox';
import { BlocksTitle } from './BlocksTitle';
import { CopySection } from './CopySection';
import { useDaypartCalendar } from './DaypartCalendarContext';
import { SpotCheckbox } from './SpotCheckbox';
import { WeekDays, getSlotId } from './utils';

export function DaypartCalendar() {
  const { blocks } = useDaypartCalendar();
  const spacing = 0.3;
  const weekDays = ['', ...WeekDays];
  const hours = [...new Array(24).keys()];
  const [copyingWeek, setCopyingWeek] = useState<string | null>(null);
  const { setValue, getValues } = useFormContext();

  return (
    <Stack direction="column" spacing={4} sx={{ pl: 1 }}>
      <Stack direction="row">
        <Stack direction="column" spacing={spacing} sx={{}}>
          {weekDays.map((name) => (
            <Typography
              key={name}
              variant="body1"
              sx={{
                width: 110,
                fontWeight: '500',
                flexShrink: 0,
                height: !name ? '28px' : '42px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Typography>
          ))}
        </Stack>
        <Stack direction="column" spacing={spacing} sx={{ overflow: 'auto' }}>
          {weekDays.map((name, weekDay) => (
            <Stack
              key={name}
              direction="row"
              spacing={spacing}
              sx={{ flexShrink: 0 }}
            >
              {hours.map((hour) =>
                weekDay === 0 ? (
                  <Typography
                    key={hour}
                    sx={{
                      flexShrink: 0,
                      height: !name ? '28px' : '42px',
                      width: '42px',
                      textAlign: 'center',
                      fontWeight: '500',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {hour.toString().padStart(2, '0')}
                  </Typography>
                ) : (
                  <SpotCheckbox key={hour} hour={hour} weekDay={weekDay - 1} />
                ),
              )}
            </Stack>
          ))}
        </Stack>
        <Stack direction="column" spacing={spacing} sx={{}}>
          {weekDays.map((name) => (
            <Box sx={{ height: !name ? '28px' : '42px' }}>
              {name && (
                <Button
                  size="small"
                  sx={{ mt: 0.4 }}
                  onClick={() => {
                    if (!copyingWeek) {
                      setCopyingWeek(name);
                      return;
                    }

                    if (copyingWeek === name) {
                      setCopyingWeek(null);
                      return;
                    }

                    const fromWeekDay = weekDays.indexOf(copyingWeek);
                    const targetWeekDay = weekDays.indexOf(name);

                    const fromSlotIds = hours.flatMap((hour) =>
                      blocks.map((block) =>
                        getSlotId(fromWeekDay - 1, hour, block.id),
                      ),
                    );
                    const toSlotIds = hours.flatMap((hour) =>
                      blocks.map((block) =>
                        getSlotId(targetWeekDay - 1, hour, block.id),
                      ),
                    );

                    fromSlotIds.forEach((element, idx) => {
                      const fromSlotId = element;
                      const toSlotId = toSlotIds[idx];

                      setValue(toSlotId, getValues(fromSlotId), {
                        shouldDirty: true,
                      });
                    });
                    setCopyingWeek(name);
                  }}
                >
                  {copyingWeek
                    ? copyingWeek === name
                      ? 'Exit'
                      : 'Paste'
                    : 'Copy'}
                </Button>
              )}
            </Box>
          ))}
        </Stack>
      </Stack>

      {!blocks.length ? (
        <Alert severity="error" sx={{ width: 400 }}>
          No blocks created for this station. Please, create some blocks first
        </Alert>
      ) : (
        <Stack direction="row">
          <BlocksTitle />
          <Stack spacing={1}>
            {blocks.map((block) => (
              <Stack
                direction="row"
                spacing={spacing}
                alignItems="center"
                key={block.id}
              >
                <FormControlLabel
                  sx={{ userSelect: 'none' }}
                  control={<BlockCheckbox block={block} />}
                  label={`XX:${block.minute.toString().padStart(2, '0')} [${
                    block.priority
                  }]`}
                />
              </Stack>
            ))}
          </Stack>
          <CopySection />
        </Stack>
      )}
    </Stack>
  );
}
