import { FormGetter } from '@kirz/mui-admin';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { getWeekDay } from 'utils/getWeekDay';

import { useSpotOverview } from './SpotOverviewContext';
import { getSlotId } from './utils';

type Props = {
  date: string;
  hour: number;
};

export function HourCheckbox({ date, hour }: Props) {
  const { setValue } = useFormContext();
  const { spot, stationSlotsAtom, daypartSlotsAtom } = useSpotOverview();
  const daypartSlots = useAtomValue(daypartSlotsAtom);
  const stationSlots = useAtomValue(stationSlotsAtom);

  const weekDay = getWeekDay(date);

  const slotIds = daypartSlots
    .filter(
      (x) =>
        x.weekDay === weekDay &&
        x.hour === hour &&
        !spot.disabledSlots[
          `${date}_${hour}_${
            stationSlots.find((y) => y.blockId === x.blockId)!.minute
          }`
        ],
    )
    .map((daypartSlot) =>
      getSlotId(
        spot.daypart.id,
        date,
        daypartSlot.daypartSpotId,
        daypartSlot.blockId,
      ),
    );

  return (
    <FormGetter
      names={slotIds}
      render={(values) => {
        const selectedCount = Object.values(values).filter((x) => !!x).length;
        const selectionState = !selectedCount
          ? false
          : selectedCount === slotIds.length
          ? true
          : 'indeterminate';

        return (
          <FormControlLabel
            control={
              <Checkbox
                disabled={!slotIds.length}
                size="small"
                checked={!!selectionState}
                indeterminate={selectionState === 'indeterminate'}
                onClick={() => {
                  slotIds.forEach((slotId) => {
                    setValue(slotId, !selectionState, {
                      shouldDirty: true,
                    });
                  });
                }}
                color="default"
                sx={{
                  opacity: 0.7,
                  '& .MuiSvgIcon-root': { fontSize: 16 },
                }}
              />
            }
            label=""
            labelPlacement="start"
          />
        );
      }}
    />
  );
}
