export const Permissions = {};

export const UserRole = {
  USER: 'user',
  ADMIN: 'admin',
};

export const UserRoles = [
  {
    value: UserRole.USER,
    text: 'User',
  },
  {
    value: UserRole.ADMIN,
    text: 'Admin',
  },
];
