import { createContext } from 'react';

import { Station } from 'types/entities';

export type SettingsContextType = {
  stations: Station[];
  reloadSettings: () => Promise<void>;
};

export const SettingsContext = createContext<SettingsContextType>({} as any);
