export const SourceType = {
  TELEGRAM: 'telegram',
};

export const SourceTypes = [{ value: SourceType.TELEGRAM, text: 'Telegram' }];

export const PostType = {
  TEXT: 'text',
  DOCUMENT: 'document',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const PostTypes = [
  { value: PostType.TEXT, text: 'Text' },
  { value: PostType.DOCUMENT, text: 'Document' },
  { value: PostType.IMAGE, text: 'Image' },
  { value: PostType.VIDEO, text: 'Video' },
];

export const SpotType = {
  FREE: 'free',
  CONNECTED: 'connected',
  TIME_RESTRICT: 'time-restrict',
};

export const SpotTypes = [
  { value: SpotType.FREE, text: 'Free' },
  { value: SpotType.TIME_RESTRICT, text: 'Time restrict' },
  { value: SpotType.CONNECTED, text: 'Connected' },
];

export const SpotWeights = [
  { value: 1, text: 'Lowest' },
  { value: 2, text: 'Low' },
  { value: 3, text: 'Medium' },
  { value: 4, text: 'High' },
  { value: 5, text: 'Highest' },
];
