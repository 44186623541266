import {
  FormInput,
  SelectInput,
  SubmitButton,
  Form,
  Grid,
  NotificationsContext,
} from '@kirz/mui-admin';
import {
  Alert,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { CompanyLogo } from 'components/CompanyLogo';
import { api } from 'services/api';
import { LoginError, LoginPayload, UserIdentity } from 'types/auth';

export function Login() {
  const [loginStep, setLoginStep] = useState<
    'email' | 'identity' | 'code' | 'password'
  >('email');
  const [identities, setIdentities] = useState<UserIdentity[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { showAlert } = useContext(NotificationsContext);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const theme = useTheme();

  const handleSubmit = async (payload: LoginPayload) => {
    const { success, errorCode, errorData } = await api.login(payload);
    if (success) {
      setIsAlertVisible(true);
      // await initialize!();

      return;
    }

    setErrorMessage(null);
    setInfoMessage(null);

    switch (errorCode) {
      // case LoginError.PHONE_VALIDATION_REQUIRED: {
      //   setLoginStep('code');
      //   setInfoMessage('Verification code was sent to your email');
      //   break;
      // }
      case LoginError.IDENTITY_SELECTION_REQUIRED: {
        setLoginStep('identity');
        setIdentities(errorData.identities as UserIdentity[]);
        setInfoMessage('Your accouunt has multiple access levels');
        break;
      }
      case LoginError.PASSWORD_VALIDATION_REQUIRED: {
        setLoginStep('password');
        setInfoMessage('Enter your password');
        break;
      }
      case LoginError.IDENTITY_NOT_FOUND: {
        setErrorMessage('User not found');
        setLoginStep('email');
        break;
      }
      case LoginError.WRONG_PASSWORD: {
        setErrorMessage('Wrong password');
        break;
      }
      case LoginError.WRONG_VALIDATION_CODE: {
        setErrorMessage('Wrong verification code');
        break;
      }
      default: {
        throw new Error(`Unknown error: ${errorCode}`);
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('status') === 'fail') {
      showAlert('Magic link invalid or expired. Please try again', 'error', {
        autoHideDuration: 5000,
        variant: 'error',
      });

      window.history.replaceState(null, '', `${window.location.pathname}}`);
    }
  }, []);

  return (
    <Container
      maxWidth="sm"
      sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <Paper elevation={16} sx={{ flex: 1, p: 4, width: '100%' }}>
        <Stack
          alignItems="center"
          spacing={1}
          sx={{ position: 'relative', pt: 2 }}
        >
          <CompanyLogo
            htmlColor={theme.palette.brand.main}
            sx={{
              width: 100,
              position: 'absolute',
              right: -10,
              top: -40,
            }}
          />
          <Typography variant="h4">Login</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Manage spots in modern dashboard with SpotPlanner
          </Typography>
          <Form
            sx={{ pt: 2, alignSelf: 'stretch' }}
            onSubmit={handleSubmit}
            spacing={3}
            shouldUnregister={false}
            dirtySubmit={false}
          >
            {infoMessage && (
              <Grid xs={12}>
                <Alert severity="warning">{infoMessage}</Alert>
              </Grid>
            )}
            {errorMessage && (
              <Grid xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}

            {isAlertVisible ? (
              <Grid xs={12}>
                <Alert severity="info">
                  We've sent you an email with a magic link! Please check your
                  inbox and click the link to log in.
                </Alert>
              </Grid>
            ) : (
              <>
                <FormInput
                  label="E-mail"
                  clearable
                  required
                  name="email"
                  type="email"
                  disabled={loginStep !== 'email'}
                  autoFocus
                  placeholder="your-email@gmail.com"
                />
                {loginStep === 'identity' && (
                  <SelectInput
                    label="Company"
                    required
                    name="identityId"
                    items={identities.map((x) => ({
                      value: x.companyId,
                      text: x.companyName,
                    }))}
                  />
                )}
                <SubmitButton
                  variant="contained"
                  size="large"
                  grid
                  fullWidth
                  xs={12}
                >
                  Continue
                </SubmitButton>
              </>
            )}
          </Form>
        </Stack>
      </Paper>
    </Container>
  );
}
