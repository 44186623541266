import { Link, Typography } from '@mui/material';
import React from 'react';

type Props = {
  url: string;
  text: string;
};

export function GridNameColumn({ url, text }: Props) {
  return (
    <Link href={url} sx={{ color: 'inherit' }}>
      <Typography variant="body2" fontWeight="500">
        {text}
      </Typography>
    </Link>
  );
}
