import {
  AutocompleteInput,
  CheckboxInput,
  ConditionalInput,
  ConfigurationContext,
  DataTableExRef,
  DateInput,
  FileInput,
  FormGetter,
  FormInput,
  FormPageLayout,
  FormSetter,
  HiddenInput,
  SelectInput,
  SubmitButton,
} from '@kirz/mui-admin';
import { Add, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Calendar,
  ClipboardListOutline,
  ViewGridOutline,
  Waves as WavesIcon,
} from 'mdi-material-ui';
import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormNavigateButton } from 'components/FormNavigateButton';
import { SpotVariantsTable } from 'components/SpotVariantsTable';
import { SpotType, SpotTypes } from 'constants/other';
import { AppStateContext } from 'contexts/AppStateContext';
import { UserContext } from 'contexts/UserContext';
import { useAppState } from 'hooks/useAppState';

function UploadFile({ tableRef }: any) {
  const { user } = useContext(UserContext);
  const routeParams = useParams() as Record<string, string>;
  const spotId = parseInt(routeParams.id, 10);
  const { hasura } = useContext(ConfigurationContext);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <>
      {!isUploading && (
        <FileInput
          name="fileId"
          mode="hasura"
          formFetcherValueResolver={null}
          formSubmitterValueResolver={null}
          md={12}
        />
      )}

      <FormSetter
        render={(setValue) => {
          return (
            <FormGetter
              names={['fileId']}
              onChange={async ({ fileId }) => {
                if (!fileId) {
                  return;
                }

                setIsUploading(true);

                await hasura.request({
                  type: 'mutation',
                  action: 'insertOne',
                  source: 'spotVariant',
                  item: {
                    companyId: user.companyId,
                    spotId,
                    fileId,
                  },
                });

                setIsUploading(false);

                tableRef.current?.reload();
              }}
            />
          );
        }}
      />
    </>
  );
}
export function Spot() {
  const { setSelectedStationId } = useAppState();
  const { user } = useContext(UserContext);
  const { selectedStationId } = useContext(AppStateContext);
  const routeParams = useParams() as Record<string, string>;
  const spotId = parseInt(routeParams.id, 10);

  const tableRef = useRef<DataTableExRef>(null);

  return (
    <FormPageLayout
      source="waveSpot"
      breadcrumbs={[
        (item) => ({
          icon: ClipboardListOutline,
          text: item.campaign?.name,
          href: `/campaigns/${item.campaign?.id}`,
        }),
        (item) => ({
          text: `${item.name ?? ''}`,
          copyOnClick: true,
        }),
      ]}
      hideSaveButton
      breadcrumbsDeps={['name', 'wave', 'campaign']}
      defaultRoute="/waves"
      formFetcherProps={{
        onFetch(item) {
          setSelectedStationId(item?.campaign?.station?.id);

          return item;
        },
        onSelection(selections) {
          return [
            ...selections,
            'wave { id name }',
            'campaign { id name station { id name } }',
          ];
        },
      }}
    >
      <HiddenInput name="wave" formSubmitterValueResolver={null} />
      <HiddenInput name="campaign" formSubmitterValueResolver={null} />
      <Grid container alignItems="flex-start" sx={{ pt: 2 }}>
        <FormGetter
          names={['campaign']}
          render={(x) => (
            <>
              {x.campaign ? (
                <FormNavigateButton
                  label="Campaign"
                  url={`/campaigns/${x.campaign?.id}`}
                  text={x.campaign.name}
                  Icon={ClipboardListOutline}
                  color="#001fbf"
                />
              ) : (
                <Grid xs={12} md={3}>
                  <Skeleton width="100%" sx={{ height: 56 }} />
                </Grid>
              )}

              <Grid xs={0} md={8} />
            </>
          )}
        />

        <FormGetter
          names={['wave']}
          render={(x) => (
            <>
              {x.wave ? (
                <FormNavigateButton
                  label="Wave"
                  url={`/waves/${x.wave?.id}`}
                  text={x.wave.name}
                  Icon={WavesIcon}
                  color="#936000"
                />
              ) : (
                <Grid xs={12} md={4}>
                  <Skeleton width="100%" sx={{ height: 56 }} />
                </Grid>
              )}

              <Grid xs={0} md={8} />
            </>
          )}
        />

        <FormInput
          name="name"
          label="Spot"
          required
          sm={6}
          md={3}
          sx={{
            fieldset: {
              borderColor: 'brown',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ViewGridOutline htmlColor="brown" />
              </InputAdornment>
            ),
          }}
        />
        <AutocompleteInput
          name="daypartId"
          sm={6}
          label="Daypart"
          required
          mode="hasura"
          source="daypart"
          filter={{
            companyId: { _eq: user.companyId },
            stationId: { _eq: selectedStationId },
          }}
          selection="id name"
          itemText="name"
          itemValue="id"
          md={3}
        />
        <DateInput name="startDate" label="From date" required md={3} sm={6} />
        <DateInput name="endDate" label="To date" required md={3} sm={6} />
        <Grid xs={12} container>
          <SelectInput
            name="type"
            label="Type"
            items={SpotTypes}
            required
            md={3}
            sm={6}
          />

          <ConditionalInput
            deps={['type']}
            variants={[
              {
                if: (y) => y.type !== SpotType.CONNECTED,
                input: (y) => (
                  <FormInput
                    name="numberPerDay"
                    label="Spots/day"
                    type="number"
                    clearable
                    required
                    md={3}
                    sm={6}
                  />
                ),
              },
            ]}
          />

          <ConditionalInput
            deps={['type']}
            variants={[
              {
                if: (y) => y.type === SpotType.CONNECTED,
                input: (y) => (
                  <FormInput
                    name="connectionId"
                    label="Connection ID"
                    required
                    md={3}
                    sm={6}
                  />
                ),
              },
            ]}
          />

          <ConditionalInput
            deps={['type']}
            variants={[
              {
                if: (y) => y.type === SpotType.CONNECTED,
                input: (y) => (
                  <FormInput
                    name="connectionOffset"
                    label="Connection offset"
                    required
                    md={3}
                    sm={6}
                  />
                ),
              },
            ]}
          />
          {/* 
            <ConditionalInput
              deps={['type']}
              variants={[
                {
                  if: (y) => y.type === SpotType.TIME_RESTRICT,
                  input: (y) => (
                    <SelectInput
                      name="weight"
                      label="Weight"
                      required
                      md={3}
                      sm={6}
                      items={SpotWeights}
                    />
                  ),
                },
              ]}
            /> */}
          <Box sx={{ pt: 1.7, pl: 2 }}>
            <ConditionalInput
              deps={['type']}
              variants={[
                {
                  if: (y) => y.type === SpotType.TIME_RESTRICT,
                  input: (y) => (
                    <CheckboxInput
                      grid={false}
                      name="appendToEmptyBlocks"
                      label="Append to empty blocks"
                      // sx={{ mt: 2 }}
                    />
                  ),
                },
              ]}
            />
          </Box>
        </Grid>

        <Grid xs={12} container>
          <Grid md={4}>
            <FormGetter
              names={['type']}
              render={(x) => (
                <Button
                  disabled={x.type === SpotType.CONNECTED}
                  variant="contained"
                  startIcon={<Calendar />}
                  sx={{ mt: 0.7 }}
                  href="overview"
                  color="primary"
                >
                  Open Planning
                </Button>
              )}
            />
          </Grid>
          <Grid
            md={8}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <SubmitButton
              grid={false}
              variant="contained"
              activateOnDirty
              sx={{ ml: 'auto' }}
              startIcon={<Save />}
            >
              Save changes
            </SubmitButton>
          </Grid>
        </Grid>

        <Grid container xs={12} sx={{ mt: 2, alignItems: 'flex-start' }}>
          <FormGetter
            names={['id', 'companyId']}
            render={(x) => {
              return (
                <>
                  <Grid xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Typography variant="subtitle1">Variants</Typography>
                      <Button
                        startIcon={<Add />}
                        variant="outlined"
                        size="small"
                        sx={{ whiteSpace: 'pre' }}
                        onClick={() => {
                          tableRef.current?.openFormDialog();
                        }}
                      >
                        Add new
                      </Button>
                    </Stack>
                  </Grid>
                  <UploadFile tableRef={tableRef} />
                </>
              );
            }}
          />
        </Grid>
      </Grid>
      <FormGetter
        names={['campaign', 'id']}
        render={(x) => {
          return (
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <SpotVariantsTable
                id="spot-variants-table"
                disableInitialization={!x.id}
                spotId={spotId}
                ref={tableRef}
                companyId={user.companyId}
                pageSizeOptions={[10, 20, 50]}
                sx={{
                  minHeight: 400,
                  mx: -2,
                  borderColor: 'transparent',
                }}
              />
            </Box>
          );
        }}
      />
    </FormPageLayout>
  );
}
