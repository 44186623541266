import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  FileInput,
} from '@kirz/mui-admin';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BEEP_SOUND } from 'assets/beep';
import { ReactComponent as AudioMixIcon } from 'assets/icons/audio-mix.svg';

import { AudioPlayer } from './AudioPlayer';
import { SpotVariantMixpointDialog } from './SpotVariantMixpointDialog';

export const SpotVariantsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      companyId: number;
      spotId: number;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { companyId, spotId, filterColumns, ...rest } = props;
    const [mixpointEditorDialogData, setMixpointEditorDialogData] = useState<{
      spotVariantId: number;
      url: string;
      peaks: any;
      duration: number;
      mixPoint: number | null;
      beepSoundUrl: string;
    } | null>();
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'uniqueId',
            selector:
              'uniqueId mixPoint spot { station { settings { metaStationCode beepFileId } } }',
            headerName: 'ID',
            width: 130,
            sortable: false,
            valueGetter({ value, row }) {
              return `AP${
                row.spot?.station?.settings?.metaStationCode ?? 'XX'
              }${value}`;
            },
          },
          {
            field: 'fileId',
            headerName: 'File',
            sortable: false,
            width: 300,
            type: 'file',
            selector: 'file { id contentType name extension metadata }',
            fetchMetadata(row) {
              return row.file ?? null;
            },
            valueGetter({ row }) {
              return row.file?.id ?? null;
            },
          },
          {
            field: 'metadata',
            headerName: 'File Info',
            selector: false,
            width: 150,
            sortable: false,
            renderCell({ row }) {
              if (!row.file?.id) {
                return '–';
              }

              const duration = row.file?.metadata.duration;

              return (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption" sx={{ lineHeight: 1.2 }}>
                    {`${Math.floor((duration || 0) / 60)
                      .toString()
                      .padStart(2, '0')}:${Math.floor((duration || 0) % 60)
                      .toString()
                      .padStart(2, '0')}`}
                    , {row.file?.metadata.channels ?? '-'} channels
                  </Typography>
                  <Typography variant="caption" sx={{ lineHeight: 1.2 }}>
                    {row.file?.metadata.sample_rate / 1000} kHz,{' '}
                    {row.file?.metadata.max_volume ??
                      row.file?.metadata.mean_volume}
                    dB
                  </Typography>
                </Box>
              );
            },
          },
          {
            field: 'autio',
            headerName: 'Audio',
            flex: 1,
            sortable: false,
            selector: false,
            renderCell({ row }) {
              if (!row.file?.id) {
                return (
                  <Typography variant="body2" sx={{ opacity: 0.5, ml: 2 }}>
                    No audio
                  </Typography>
                );
              }

              return (
                <Stack
                  key={`${row.id}_${row.mixPoint}`}
                  direction="row"
                  alignItems="center"
                  sx={{ flex: 1, mr: 2 }}
                  spacing={1}
                >
                  <AudioPlayer
                    autoplay={false}
                    size="small"
                    url={`/api/v1/files/${row.file?.id}`}
                    peaks={row.file?.metadata.audio_peaks?.data}
                    duration={row.file?.metadata.duration}
                    mixPoint={row.mixPoint}
                    beepSoundUrl={
                      row.spot?.station?.settings?.beepFileId
                        ? `/api/v1/files/${row.spot?.station?.settings?.beepFileId}`
                        : BEEP_SOUND
                    }
                  />

                  <Tooltip title="Set Mix Point">
                    <IconButton
                      onClick={() => {
                        setMixpointEditorDialogData({
                          spotVariantId: row.id,
                          peaks: row.file?.metadata.audio_peaks?.data,
                          duration: row.file?.metadata.duration,
                          url: `/api/v1/files/${row.file?.id}`,
                          mixPoint: row.mixPoint,
                          beepSoundUrl: row.spot?.station?.settings?.beepFileId
                            ? `/api/v1/files/${row.spot?.station?.settings?.beepFileId}`
                            : BEEP_SOUND,
                        });
                      }}
                    >
                      <AudioMixIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              );
            },
          },
          {
            field: 'weight',
            headerName: 'Weight',
            selector: 'weight weightPercent',
            width: 100,
            sortable: false,
            valueGetter({ row }) {
              return row.weight;
            },
          },
          {
            field: 'weightPercent',
            headerName: 'Proportion',
            selector: false,
            width: 100,
            sortable: false,
            valueGetter({ row }) {
              return `${row.weightPercent.toFixed(0)}%`;
            },
          },
        ]),
      [filterColumns],
    );

    return (
      <>
        <DataTableEx
          {...rest}
          ref={tableRef}
          source="spotVariant"
          automaticallyOpenEditPage={false}
          columns={columns}
          persistStateMode="query"
          sortBy={rest.sortBy}
          editable={{
            onEdit(row) {
              tableRef.current?.openFormDialog(row);
            },
          }}
          formTitle={(isNew) => (isNew ? 'New variant' : 'Edit variant')}
          formDialogProps={{
            ...rest.formDialogProps,
            maxWidth: 'sm',
            formProps: {
              ...rest.formDialogProps?.formProps,
              defaultValues: {
                weight: 1,
                ...rest.formDialogProps?.formProps?.defaultValues,
              },
            },
            formSubmitterProps: {
              preSubmit(item) {
                return {
                  spotId,
                  companyId,
                  ...item,
                };
              },
            },
          }}
          selectProps={{
            onSort() {
              return { createdAt: 'DESC' };
            },
            filter: {
              spotId: { _eq: spotId },
              companyId: { _eq: companyId },
            },
          }}
        >
          <FormInput name="weight" label="Weight" type="number" required />
          <FileInput name="fileId" md={12} />
        </DataTableEx>
        {mixpointEditorDialogData && (
          <SpotVariantMixpointDialog
            open
            {...mixpointEditorDialogData}
            onClose={() => {
              setMixpointEditorDialogData(null);
            }}
          />
        )}
      </>
    );
  },
);
