import { CheckboxInput } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Checkbox, Tooltip } from '@mui/material';
import { CloseBoxOutline } from 'mdi-material-ui';
import React from 'react';
import { Controller } from 'react-hook-form';

import { SpotType } from 'constants/other';

import { useSpotOverview } from './SpotOverviewContext';
import { getSlotId } from './utils';

export type SlotCheckboxProps = {
  date: string;
  weekDay: number;
  hour: number;
  minute: number;
  daypartSpotId: number | null;
  daypartBlockId: number | null;
  singleMode?: boolean;
  allocatedBy?: string;
};

export function SlotCheckbox({
  weekDay,
  date,
  hour,
  minute,
  daypartSpotId,
  daypartBlockId,
  singleMode,
  allocatedBy,
}: SlotCheckboxProps) {
  const { spot } = useSpotOverview();

  const isSlotAvailable = !!daypartSpotId && !!daypartBlockId;

  const slotId =
    daypartSpotId && daypartBlockId
      ? getSlotId(spot.daypart.id, date, daypartSpotId, daypartBlockId)
      : null;

  if (singleMode) {
    return (
      <Controller
        name={slotId ?? ''}
        render={({ field: { onChange, value } }) => (
          <Button
            sx={{
              height: '100%',
              width: '100%',
            }}
            disabled={!isSlotAvailable && !allocatedBy}
            onClick={() => {
              onChange(!value);
            }}
          >
            <Box
              sx={{
                opacity: isSlotAvailable ? 1 : 0.25,
                pointerEvents: 'none',
              }}
            >
              <Checkbox
                checked={!!value}
                {...(allocatedBy && {
                  icon: <CloseBoxOutline sx={{ color: '#ffa1a1' }} />,
                })}
              />
            </Box>
          </Button>
        )}
      />
    );
  }

  const content = (
    <CheckboxInput
      disabled={
        !isSlotAvailable ||
        spot.type === SpotType.TIME_RESTRICT ||
        !!allocatedBy
      }
      name={slotId ?? ''}
      {...(singleMode
        ? {
            grid: false,
            size: 'medium',
            formControlLabelProps: {
              disabled: true,
              sx: {
                marginRight: 0,
                // ...(allocatedBy && {
                //   span: {
                //     color: '#121828 !important',
                //   },
                // }),
              },
            },
          }
        : {
            label: minute.toString().padStart(2, '0'),
            size: 'small',
            sx: {
              '& .MuiSvgIcon-root': { fontSize: 18 },
              padding: '5px 9px',
              // ...(allocatedBy && {
              //   color: '#121828',
              // }),
            },
            ...(allocatedBy && {
              icon: <CloseBoxOutline sx={{ color: '#ffa1a1' }} />,
            }),
            formControlLabelProps: {
              componentsProps: {
                typography: {
                  fontFamily: 'Roboto Mono',
                  fontSize: 14,
                  sx: {
                    userSelect: 'none',
                    // ...(allocatedBy && {
                    //   color: '#121828 !important',
                    // }),
                  },
                },
              },
              sx: { mr: 0 },
            },
          })}
      {...({} as any)}
    />
  );

  if (!allocatedBy) {
    return content;
  }

  return (
    <Tooltip title={allocatedBy}>
      <span>{content}</span>
    </Tooltip>
  );
}
