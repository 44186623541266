import { ConfigurationContext } from '@kirz/mui-admin';
import { Box, Button, IconButton, Stack } from '@mui/material';

// @ts-ignore
import { Howl } from 'howler';
import { Pause, Play } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
// @ts-ignore
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebounce } from 'use-debounce';
// @ts-ignore
import WaveSurfer from 'wavesurfer.js';
// @ts-ignore
import WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
// @ts-ignore
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions';

import { BEEP_SOUND } from 'assets/beep';

export function AudioPlayer(props: {
  url: string;
  beepSoundUrl?: string;
  extension?: string;
  duration?: number;
  peaks: any[];
  mixPoint: number | null;
  size?: 'large' | 'small';
  autoplay?: boolean;
  spotVariantId?: number;
}) {
  const {
    url,
    beepSoundUrl,
    peaks,
    size,
    duration,
    autoplay,
    extension,
    mixPoint,
    spotVariantId,
  } = props;

  const mixpointWidth = size === 'small' ? 2 : 4;
  const { hasura } = useContext(ConfigurationContext);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mixpoint, setMixpoint] = useState(mixPoint);
  const [adjustAmount, setAdjustAmount] = useState(0.1);
  const waveformContainerRef = useRef<any>();
  const wavesurferRef = useRef<any>();
  const mixpointRef = useRef(mixpoint);
  const isBeepPlayed = useRef(false);
  const isLoaded = useRef(false);
  const [debouncedMixpoint] = useDebounce(mixpoint, 600);
  const mixpointPositionLeft =
    mixpoint == null ? null : (mixpoint / (duration ?? 0)) * 100;
  const audioSnippet = useMemo(
    () =>
      new Howl({
        src: [url!],
        format: extension ?? 'mp3',
        preload: size === 'large',
      }),
    [],
  );

  const beepSound = useMemo(
    () =>
      new Howl({
        src: [beepSoundUrl ?? BEEP_SOUND],
        format: 'wav',
      }),
    [],
  );

  const handlePlay = () => {
    if (!mixpoint || isPlaying) {
      wavesurferRef.current.playPause();
      return;
    }

    if (isLoaded.current) {
      const currentTime = wavesurferRef.current.getCurrentTime();

      wavesurferRef.current.setCurrentTime(
        Math.abs(mixpoint - currentTime) < 0.1
          ? Math.max(currentTime - 3, 0)
          : 0,
      );
    }

    wavesurferRef.current.playPause();
  };

  const handleSetMixPoint = () => {
    const currentTime = wavesurferRef.current.getCurrentTime();
    setMixpoint(currentTime);
    drawMixPoint(currentTime);
  };

  const drawMixPoint = (time: number) => {
    const seconds =
      (mixpointWidth * (duration ?? 0)) /
      waveformContainerRef.current.offsetWidth;

    setAdjustAmount(seconds);
  };

  const adjustMixPoint = (direction: 'left' | 'right') => {
    wavesurferRef.current.pause();

    if (mixpoint !== null) {
      const newMixPoint =
        direction === 'left'
          ? mixpoint - adjustAmount
          : mixpoint + adjustAmount;

      wavesurferRef.current.setCurrentTime(newMixPoint);

      setMixpoint(newMixPoint);
      drawMixPoint(newMixPoint);
      playSnippet(newMixPoint);
    }
  };

  const playSnippet = (startTime: number) => {
    audioSnippet.pause();
    audioSnippet.off();

    audioSnippet.seek(startTime);

    audioSnippet.on('play', () => {
      setTimeout(() => {
        audioSnippet.pause();
      }, adjustAmount * 1000);
    });

    audioSnippet.play();
  };

  useEffect(() => {
    mixpointRef.current = mixpoint;
  }, [mixpoint]);

  useEffect(() => {
    if (debouncedMixpoint == null || !spotVariantId || size === 'small') {
      return;
    }

    hasura.request({
      type: 'mutation',
      action: 'update',
      source: 'spotVariant',
      where: {
        id: { _eq: spotVariantId },
      },
      set: {
        mixPoint: debouncedMixpoint,
      },
    });
  }, [debouncedMixpoint]);

  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: waveformContainerRef.current,
      responsive: true,
      ...(size === 'small'
        ? {
            barWidth: 2,
            barHeight: 1,
            barGap: 2,
            barRadius: 4,
          }
        : {}),

      height: size === 'small' ? 30 : 80,
      normalize: true,
      // waveColor: '#10182791',
      plugins: [
        WaveSurferCursor.create({
          showTime: true,
          opacity: 1,
          customShowTimeStyle: {
            'background-color': 'rgba(0,0,0,0.4)',
            color: '#fff',
            padding: '2px',
            'font-size': '12px',
          },
          formatTimeCallback: (cursorTime: number) =>
            [cursorTime].map((time) =>
              [
                Math.floor((time % 3600) / 60),
                `00${Math.floor(time % 60)}`.slice(-2),
              ].join(':'),
            ),
        }),
        RegionsPlugin.create(),
      ],
    });

    let timeout: any = null;
    wavesurfer.on('ready', () => {
      console.log('ready');

      if (autoplay ?? true) {
        wavesurfer.play();
      }
    });

    wavesurfer.on('init', () => {
      console.log('init');

      if (mixPoint !== null) {
        drawMixPoint(mixPoint);
      }
    });

    wavesurfer.on('play', () => {
      setIsPlaying(true);
      isLoaded.current = true;
      timeout = setTimeout(() => {
        wavesurfer.pause();
      }, 100);
    });

    wavesurfer.on('audioprocess', (time: number) => {
      if (
        !isBeepPlayed.current &&
        mixpointRef.current !== null &&
        time >= mixpointRef.current && // - 0.2
        time < mixpointRef.current + 0.3
      ) {
        beepSound.play();
        isBeepPlayed.current = true;

        setTimeout(() => {
          isBeepPlayed.current = false;
        }, 500);
      }
    });

    wavesurfer.on('pause', () => {
      setIsPlaying(false);
    });

    wavesurfer.on('finish', () => {
      if (size === 'large') {
        wavesurfer.seekTo(0);
        wavesurfer.play();
        return;
      }

      setIsPlaying(false);
      wavesurfer.seekTo(0);
    });

    wavesurfer.on('audioprocess', () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    });

    wavesurferRef.current = wavesurfer;

    return () => {
      wavesurferRef.current.destroy();
      audioSnippet.unload();
    };
  }, []);

  useEffect(() => {
    if (!url) {
      return;
    }

    wavesurferRef.current.load(url, peaks);

    if (mixPoint !== null) {
      drawMixPoint(mixPoint);
    }
  }, [url]);

  useHotkeys(
    'p',
    async () => {
      wavesurferRef.current.playPause();
    },
    { enabled: size === 'large' },
    [],
  );

  useHotkeys(
    'm',
    async () => {
      handleSetMixPoint();
    },
    { enabled: size === 'large' },
    [handleSetMixPoint],
  );

  useHotkeys(
    'ArrowLeft',
    async () => {
      adjustMixPoint('left');
    },
    { enabled: size === 'large' },
    [adjustMixPoint],
  );

  useHotkeys(
    'ArrowRight',
    async () => {
      adjustMixPoint('right');
    },
    { enabled: size === 'large' },
    [adjustMixPoint],
  );

  const Icon = isPlaying ? Pause : Play;
  return (
    <Stack
      direction="column"
      spacing={3}
      sx={{ flex: 1, position: 'relative' }}
    >
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          flex: 1,
          alignItems: 'center',
        }}
      >
        {size === 'small' && (
          <IconButton
            size={size === 'small' ? 'small' : 'large'}
            sx={{ mr: size === 'small' ? 0.4 : 1 }}
            disabled={!url}
            onClick={() => {
              handlePlay();
            }}
          >
            <Icon fontSize={size === 'small' ? 'small' : 'large'} />
          </IconButton>
        )}

        <Box sx={{ flex: 1, position: 'relative' }}>
          <Box
            sx={{
              flexGrow: 1,
              position: 'relative',
              overflow: 'hidden',
              '& wave': {
                overflow: 'hidden !important',
              },
              mr: 0,
            }}
            ref={waveformContainerRef}
          >
            <Box
              position="absolute"
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  borderBottom: 'thin solid #ccc',
                  opacity: 0.5,
                }}
              />
            </Box>
          </Box>

          {mixpointPositionLeft !== null && (
            <Box
              sx={{
                position: 'absolute',
                left: `${mixpointPositionLeft}%`,
                top: 0,
                height: '100%',
                width: mixpointWidth,
                background: 'red',
                zIndex: 10,
              }}
            />
          )}

          {/* {size === 'large' && <Box ref={timelineContainerRef} sx={{ mt: 1 }} />} */}
        </Box>
      </Box>
      {size === 'large' && (
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            direction: 'row',
            alignItems: 'center',
            // alignSelf: 'center',
          }}
        >
          <Button
            sx={{ width: 160, mr: 'auto' }}
            variant="contained"
            disabled={!url}
            onClick={() => {
              handlePlay();
            }}
          >
            {isPlaying ? 'Stop' : 'Play'} [P]
          </Button>
          <Button
            sx={{ width: 160, ml: 2 }}
            variant="contained"
            disabled={!url}
            onClick={() => {
              handleSetMixPoint();
            }}
          >
            Set Mix Point [M]
          </Button>
          <Button
            sx={{ width: 160, ml: 2 }}
            variant="contained"
            disabled={!mixpoint || isPlaying}
            onClick={() => {
              adjustMixPoint('left');
            }}
          >
            Adjust Left [←]
          </Button>
          <Button
            sx={{ width: 160, ml: 2 }}
            variant="contained"
            disabled={!mixpoint || isPlaying}
            onClick={() => {
              adjustMixPoint('right');
            }}
          >
            Adjust Right [→]
          </Button>
        </Box>
      )}
    </Stack>
  );
}
