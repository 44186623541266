import {
  ConfigurationContext,
  FormGetter,
  FormPageLayout,
} from '@kirz/mui-admin';
import { ClipboardListOutline } from 'mdi-material-ui';
import React, { useContext, useRef } from 'react';

import { useAppState } from 'hooks/useAppState';
import { getDaysArray } from 'utils/getDaysArray';
import { getWeekDay } from 'utils/getWeekDay';

import { SpotOverviewCalendar } from './sections/SpotOverviewCalendar';
import { FetchedSpot } from './sections/types';
import { getSlotId, splitSlotId } from './sections/utils';

export function SpotOverview() {
  const { setSelectedStationId } = useAppState();
  const originalSlotsRef = useRef<Record<string, boolean>>();
  const { hasura } = useContext(ConfigurationContext);

  return (
    <FormPageLayout
      hideSaveButton
      source="waveSpot"
      breadcrumbs={[
        (item) => ({
          icon: ClipboardListOutline,
          text: item.campaign?.name,
          href: `/campaigns/${item.campaign?.id}`,
        }),
        (item) => ({
          text: `${item.name ?? ''}`,
          href: `/spots/${item.id}`,
        }),
        (item) => ({
          text: `Planning`,
        }),
      ]}
      breadcrumbsDeps={['name', 'wave', 'campaign']}
      defaultRoute="/waves"
      formProps={{
        shouldUnregister: false,
        shouldUseNativeValidation: false,
        shouldFocusError: false,
      }}
      formFetcherProps={{
        onSelection() {
          return [
            'id',
            'name',
            'isPlanned',
            'campaign { id name }',
            'type',
            'wave { id name }',
            'startDate',
            'endDate',
            'numberPerDay',
            'station { id blocks (orderBy: { minute: ASC } ) { id minute } campaigns { id name waves { id name spots { id name slots { block { id minute } spot { id hour } date } } } } }',
            `daypart { id spots { id weekDay hour block { id minute } } }`,
            'slots { block { id minute } spot { id hour } date }',
          ];
        },

        onFetch(item: FetchedSpot) {
          setSelectedStationId(item?.station?.id);

          const dates = getDaysArray(item.startDate, item.endDate);

          originalSlotsRef.current = {
            ...Object.fromEntries(
              dates.flatMap((date) => {
                const weekDay = getWeekDay(date);
                return item.daypart.spots
                  .filter((x) => x.weekDay === weekDay)
                  .map((spot) => {
                    return [
                      getSlotId(item.daypart.id, date, spot.id, spot.block.id),
                      false,
                    ];
                  });
              }),
            ),
            ...Object.fromEntries(
              item.slots.map((slot) => [
                getSlotId(
                  item.daypart.id,
                  slot.date,
                  slot.spot.id,
                  slot.block.id,
                ),
                true,
              ]),
            ),
          };

          return {
            ...item,
            ...originalSlotsRef.current,
          };
        },
      }}
      formSubmitterProps={{
        mode: 'none',
        async preSubmit(item) {
          const clearObject = (obj: Record<string, any>) => {
            return Object.fromEntries(
              Object.entries(obj).filter(([key, value]) =>
                key.startsWith('slot_'),
              ),
            );
          };

          const originalSlots = originalSlotsRef.current!;
          const newSlots = clearObject(item);

          const addedSlots: string[] = [];
          const removedSlots: string[] = [];

          const allKeys = new Set([
            ...Object.keys(originalSlots),
            ...Object.keys(newSlots),
          ]);

          allKeys.forEach((key) => {
            const val1 = originalSlots[key] || false;
            const val2 = newSlots[key] || false;

            if (val1 === false && val2 === true) {
              addedSlots.push(key);
            } else if (val1 === true && val2 === false) {
              removedSlots.push(key);
            }
          });

          const deleteWhere = removedSlots.length && {
            _or: removedSlots.map((id) => {
              const { date, blockId, daypartSpotId, daypartId } =
                splitSlotId(id);

              return {
                spotId: { _eq: item.id },
                daypartId: { _eq: daypartId },
                daypartSpotId: { _eq: daypartSpotId },
                blockId: { _eq: blockId },
                date: { _eq: date },
              };
            }),
          };

          await Promise.all([
            ...(deleteWhere
              ? [
                  hasura.request({
                    action: 'delete',
                    source: 'spotSelectedSlot',
                    type: 'mutation',
                    where: deleteWhere,
                  }),
                ]
              : []),
            ...(addedSlots.length
              ? [
                  hasura.request({
                    source: 'spotSelectedSlot',
                    type: 'mutation',
                    action: 'insert',
                    items: addedSlots.map((id) => {
                      const { date, blockId, daypartSpotId, daypartId } =
                        splitSlotId(id);

                      return {
                        spotId: item.id,
                        daypartId,
                        daypartSpotId,
                        blockId,
                        date,
                      };
                    }),
                  }),
                ]
              : []),
          ]);

          originalSlotsRef.current = newSlots;

          return item;
        },
      }}
    >
      <FormGetter
        names={[
          'id',
          'name',
          'wave',
          'campaign',
          'startDate',
          'endDate',
          'station',
          'daypart',
          'isPlanned',
          'slotsMap',
          'type',
          'numberPerDay',
        ]}
        render={(values: FetchedSpot) => {
          if (!values.id) {
            return null;
          }

          return (
            <SpotOverviewCalendar
              spot={{
                ...values,
                disabledSlots: Object.fromEntries(
                  values.station.campaigns.flatMap((campaign) =>
                    campaign.waves.flatMap((wave) =>
                      wave.spots
                        .filter((spot) => spot.id !== values.id)
                        .flatMap((spot) =>
                          spot.slots.map((slot) => [
                            `${slot.date}_${slot.spot.hour}_${slot.block.minute}`,
                            `${campaign.name}: ${spot.name}`,
                          ]),
                        ),
                    ),
                  ),
                ),
              }}
              stationSlots={values.station.blocks.map((x) => ({
                blockId: x.id,
                minute: x.minute,
              }))}
              daypartSlots={values.daypart.spots.map((x) => ({
                daypartSpotId: x.id,
                hour: x.hour,
                weekDay: x.weekDay,
                minute: x.block.minute,
                blockId: x.block.id,
              }))}
              originalSlots={originalSlotsRef.current!}
            />
          );
        }}
      />
    </FormPageLayout>
  );
}
