import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import { Print } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton } from '@mui/material';
import {
  TextBoxPlusOutline,
  ViewGridOutline,
  Waveform,
  Waves,
} from 'mdi-material-ui';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { ActiveCampaignsOverview } from 'components/ActiveCampaignsOverview';
import { CampaignsAlertArea } from 'components/CampaignsAlertArea';
import { ColumnItemsMenu } from 'components/ColumnItemsMenu';
import { GridNameColumn } from 'components/GridNameColumn';
import { AppStateContext } from 'contexts/AppStateContext';
import { UserContext } from 'contexts/UserContext';

function PrintButton({ url }: { url: string }) {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <CircularProgress size={14} />;
  }

  return (
    <IconButton
      onClick={() => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);

        window.location.href = url;
      }}
    >
      <Print />
    </IconButton>
  );
}

export function Campaigns() {
  const { selectedStationId } = useContext(AppStateContext);
  const { user } = useContext(UserContext);
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell({ row }) {
          return (
            <GridNameColumn url={`/campaigns/${row.id}`} text={row.name} />
          );
        },
      },
      {
        field: 'waves',
        headerName: 'Waves',
        flex: 1,
        sortable: false,
        selector: 'waves { id name }',
        renderCell({ row }) {
          return (
            <ColumnItemsMenu
              text={`${row.waves.length} waves`}
              icon={<Waves />}
              items={row.waves.map((wave: any) => ({
                link: `/waves/${wave.id}`,
                text: wave.name,
              }))}
            />
          );
        },
      },
      {
        field: 'spots',
        headerName: 'Spots',
        flex: 1,
        sortable: false,
        selector:
          'waves { name spots { id name variantsAggregate {aggregate{count}} } }',
        renderCell({ row }) {
          return (
            <ColumnItemsMenu
              text={`${row.waves.flatMap((x: any) => x.spots).length} spots`}
              icon={<ViewGridOutline />}
              items={row.waves.flatMap((wave: any) =>
                wave.spots.map((spot: any) => ({
                  link: `/spots/${spot.id}`,
                  text: `${wave.name}: ${spot.name}`,
                  icon: (
                    <Waveform
                      sx={{
                        opacity:
                          spot.variantsAggregate.aggregate.count > 0 ? 1 : 0.2,
                      }}
                    />
                  ),
                  ...(spot.variantsAggregate.aggregate.count > 0 && {
                    tooltip: 'Audio uploaded',
                  }),
                })),
              )}
            />
          );
        },
      },
      {
        field: 'firstWave',
        headerName: 'First spot',
        flex: 1,
        type: 'date',
        selector:
          'firstWave: waves(orderBy: {spotsAggregate: {min: {startDate: ASC}}}, limit: 1) { spots(orderBy: {startDate: ASC}, limit: 1) { startDate } }',
        valueGetter({ value }) {
          return value[0]?.spots?.[0]?.startDate ?? null;
        },
        sortable: false,
      },
      {
        field: 'lastWave',
        headerName: 'Last spot',
        flex: 1,
        type: 'date',
        selector:
          'lastWave: waves(orderBy: {spotsAggregate: {min: {endDate: DESC}}}, limit: 1) { spots(orderBy: {endDate: DESC}, limit: 1) { endDate } }',
        valueGetter({ value }) {
          return value[0]?.spots?.[0]?.endDate ?? null;
        },
        sortable: false,
      },
      {
        field: 'planned',
        headerName: 'Planned spots',
        width: 150,
        sortable: false,
        selector:
          'waves { spotsAggregate(where:{isPlanned:{_eq: true}}) { aggregate { count } } }',
        valueGetter({ row }) {
          return row.waves.reduce(
            (acc: any, item: any) => acc + item.spotsAggregate.aggregate.count,
            0,
          );
        },
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
      {
        field: 'actions',
        headerName: '',
        selector: false,
        type: 'actions',
        width: 50,
        renderCell({ row }) {
          return <PrintButton url={`/api/v1/campaigns/${row.id}/report`} />;
        },
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Campaigns"
      actionContent={
        <Button
          sx={{ ml: 5 }}
          variant="contained"
          startIcon={<TextBoxPlusOutline />}
          onClick={async () => {
            tableRef.current?.openFormDialog();
          }}
        >
          Add campaign
        </Button>
      }
    >
      <Box sx={{ position: 'relative', flex: 1, display: 'flex' }}>
        <DataTableEx
          id="campaigns-table"
          ref={tableRef}
          automaticallyOpenEditPage={false}
          source="campaign"
          columns={columns}
          editPageUrl="/campaigns"
          sortBy={{ field: 'createdAt', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? 'New campaign' : 'Edit campaign')}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
            slots: {
              afterSearch: <ActiveCampaignsOverview />,
            },
            containerStyle: {
              height: 120,
              alignItems: 'flex-end',
            },
          }}
          selectProps={{
            filter: {
              companyId: { _eq: user.companyId },
              stationId: { _eq: selectedStationId },
            },
          }}
          formDialogProps={{
            formSubmitterProps: {
              preSubmit(item) {
                return {
                  ...item,
                  companyId: user.companyId,
                  stationId: selectedStationId,
                };
              },
            },
          }}
        >
          <FormInput name="name" label="Name" required />
        </DataTableEx>
        <CampaignsAlertArea />
      </Box>
    </TablePageLayout>
  );
}
