import { FormInput, useNavigate, Grid } from '@kirz/mui-admin';
import {
  Button,
  createTheme,
  InputAdornment,
  ThemeProvider,
  useTheme,
} from '@mui/material';
import { OpenInNew } from 'mdi-material-ui';
import React, { FC } from 'react';

type Props = {
  url: string;
  text: string;
  color: string;
  label: string;
  Icon: FC<any>;
};

export function FormNavigateButton({ url, text, color, label, Icon }: Props) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <ThemeProvider
      theme={createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            main: color,
          },
        },
      })}
    >
      <Grid xs={12} md={3}>
        <Button
          fullWidth
          sx={{ p: 0 }}
          onClick={() => {
            navigate(url);
          }}
        >
          <FormInput
            name={url}
            value={text}
            sx={{
              pointerEvents: 'none',
              fieldset: {
                borderColor: color,
              },
            }}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon htmlColor={color} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <OpenInNew />
                </InputAdornment>
              ),
            }}
            label={label}
            clearable={false}
            grid={false}
          />
        </Button>
      </Grid>
    </ThemeProvider>
  );
}
