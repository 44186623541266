import {
  DataTableExRef,
  FormInput,
  FormPageLayout,
  HiddenInput,
  SubmitButton,
} from '@kirz/mui-admin';
import { Add, Print, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { ClipboardListOutline } from 'mdi-material-ui';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CampaignWavesTable } from 'components/CampaignWavesTable';
import { useAppState } from 'hooks/useAppState';

function PrintButton({ url }: { url: string }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingButton
      variant="contained"
      sx={{ ml: 'auto' }}
      startIcon={<Print />}
      loading={isLoading}
      onClick={() => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        window.location.href = url;
      }}
    >
      Print Report
    </LoadingButton>
  );
}

export function Campaign() {
  const { setSelectedStationId } = useAppState();
  const routeParams = useParams() as Record<string, string>;
  const campaignId = parseInt(routeParams.id, 10);
  const tableRef = useRef<DataTableExRef>(null);

  return (
    <FormPageLayout
      source="campaign"
      breadcrumbs={[
        {
          text: 'Campaigns',
          icon: ClipboardListOutline,
          href: '/campaigns',
        },
        (item) => ({
          text: `${item.name}`,
          copyOnClick: true,
        }),
      ]}
      hideSaveButton
      breadcrumbsDeps={['name']}
      defaultRoute="/campaigns"
      formFetcherProps={{
        onFetch(item) {
          setSelectedStationId(item?.station?.id);

          return item;
        },
        onSelection(selections) {
          return [...selections, 'station { id name }'];
        },
      }}
    >
      <HiddenInput name="station" formSubmitterValueResolver={null} />
      <Grid container alignItems="flex-start" sx={{ pt: 2 }}>
        <FormInput
          name="name"
          sx={{
            fieldset: {
              borderColor: '#001fbf',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <ClipboardListOutline htmlColor="#001fbf" />
              </InputAdornment>
            ),
          }}
          label="Campaign"
          required
          md={3}
        />
        <Grid
          xs={12}
          md={8}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <PrintButton url={`/api/v1/campaigns/${campaignId}/report`} />

          <SubmitButton
            grid={false}
            variant="contained"
            activateOnDirty
            sx={{ ml: 2 }}
            startIcon={<Save />}
          >
            Save changes
          </SubmitButton>
        </Grid>
      </Grid>

      <Box
        sx={{
          mt: 5,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <CampaignWavesTable
          id="campaign-waves-table"
          ref={tableRef}
          editPageUrl="/waves"
          campaignId={campaignId}
          searchFilter={
            {
              slots: {
                beforeSearch: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Waves</Typography>
                    <Button
                      startIcon={<Add />}
                      variant="outlined"
                      size="small"
                      sx={{ mx: 2, whiteSpace: 'pre' }}
                      onClick={() => {
                        tableRef.current?.openFormDialog();
                      }}
                    >
                      Add new
                    </Button>
                  </Box>
                ),
              },
            } as any
          }
          sortBy={{ field: 'serialNumber', sort: 'asc' }}
          sx={{
            mt: 1,
            minHeight: 400,
            mx: -2,
            borderColor: 'transparent',
          }}
        />
      </Box>
    </FormPageLayout>
  );
}
