import { DataTableExRef, FormInput, FormPageLayout } from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Tab, Tabs } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Domain } from 'mdi-material-ui';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CompanyStationsTable } from 'components/CompanyStationsTable';
import { CompanyUsersTable } from 'components/CompanyUsersTable';
import { useSettings } from 'hooks/useSettings';
import { HideStationSelector } from 'layouts/HideStationSelector';

export function Company() {
  const { reloadSettings } = useSettings();
  const routeParams = useParams() as Record<string, string>;
  const [selectedTab, setSelectedTab] = useState<'stations' | 'users'>(
    'stations',
  );
  const companyId = parseInt(routeParams.id, 10);
  const tableRef = useRef<DataTableExRef>(null);

  return (
    <HideStationSelector>
      <FormPageLayout
        source="company"
        breadcrumbs={[
          {
            text: 'Companies',
            icon: Domain,
            href: '/companies',
          },
          (item) => ({
            text: `#${item.id} ${item.name}`,
            copyOnClick: true,
          }),
        ]}
        breadcrumbsDeps={['name', 'id']}
        defaultRoute="/dayparts"
      >
        <Grid container alignItems="flex-start">
          <FormInput name="name" label="Name" required md={4} />
        </Grid>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={(e, v) => setSelectedTab(v)}
            sx={{
              mt: { xs: 2, md: 4, lg: 4 },
            }}
          >
            <Tab label="Stations" value="stations" />
            <Tab label="Users" value="users" />

            <Button
              startIcon={<Add />}
              variant="outlined"
              size="small"
              sx={{ ml: 'auto', alignSelf: 'center' }}
              onClick={() => {
                tableRef.current?.openFormDialog();
              }}
            >
              Add new
            </Button>
          </Tabs>

          {selectedTab === 'stations' && (
            <CompanyStationsTable
              id="company-stations-table"
              ref={tableRef}
              sx={{
                mt: 1,
                minHeight: 400,
                mx: -2,
                borderColor: 'transparent',
              }}
              selectProps={{
                filter: {
                  companyId: { _eq: companyId },
                },
              }}
              deletable={{
                onDeleted() {
                  reloadSettings();
                },
              }}
              formDialogProps={{
                formProps: {
                  defaultValues: {
                    companyId,
                  },
                },
                formSubmitterProps: {
                  onSubmit() {
                    reloadSettings();
                  },
                },
              }}
            />
          )}

          {selectedTab === 'users' && (
            <CompanyUsersTable
              id="company-users-table"
              ref={tableRef}
              companyId={companyId}
              sx={{
                mt: 1,
                minHeight: 400,
                mx: -2,
                borderColor: 'transparent',
              }}
              selectProps={{
                filter: {
                  companyId: { _eq: companyId },
                },
              }}
              formDialogProps={{
                formProps: {
                  defaultValues: {
                    companyId,
                    role: 'user',
                  },
                },
              }}
            />
          )}
        </Box>
      </FormPageLayout>
    </HideStationSelector>
  );
}
