import dayjs from 'dayjs';

export function getWeeksBetweenDates(
  startDate: string,
  endDate: string,
  step = 7,
): { start: string; end: string; step?: number }[] {
  const weeks = [];
  let currentWeekStart = dayjs(startDate);
  const endOfWeek = dayjs(endDate);

  while (
    currentWeekStart.isBefore(endOfWeek) ||
    currentWeekStart.isSame(endOfWeek)
  ) {
    const weekEnd = currentWeekStart.clone().add(step - 1, 'day');
    weeks.push({
      start: currentWeekStart.format('YYYY-MM-DD'),
      end: weekEnd.format('YYYY-MM-DD'),
    });
    currentWeekStart = currentWeekStart.add(step, 'day');
  }

  weeks[weeks.length - 1].end = endDate;
  return weeks;
}
