import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close } from 'mdi-material-ui';
import React from 'react';

import { AudioPlayer } from './AudioPlayer';

export type SpotVariantMixpointDialogProps = {
  spotVariantId: number;
  duration: number;
  url: string;
  beepSoundUrl: string;
  peaks: any;
  mixPoint: number | null;
  open: boolean;
  onClose: () => void;
};

export function SpotVariantMixpointDialog({
  spotVariantId,
  url,
  beepSoundUrl,
  duration,
  peaks,
  mixPoint,
  open,
  onClose,
}: SpotVariantMixpointDialogProps) {
  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        Mix Point Editor
        <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
          <Close />
        </IconButton>{' '}
      </DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <AudioPlayer
          size="large"
          autoplay={false}
          url={url}
          beepSoundUrl={beepSoundUrl}
          peaks={peaks}
          extension="mp3"
          duration={duration}
          mixPoint={mixPoint}
          spotVariantId={spotVariantId}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{ ml: 'auto' }} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
