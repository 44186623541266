import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
} from '@kirz/mui-admin';
import { ViewGridOutline, Waveform } from 'mdi-material-ui';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { AppStateContext } from 'contexts/AppStateContext';
import { UserContext } from 'contexts/UserContext';

import { ColumnItemsMenu } from './ColumnItemsMenu';
import { GridNameColumn } from './GridNameColumn';

export const CampaignWavesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      campaignId: number;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { user } = useContext(UserContext);
    const { selectedStationId } = useContext(AppStateContext);
    const { campaignId, filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell({ row }) {
              return (
                <GridNameColumn url={`/waves/${row.id}`} text={row.name} />
              );
            },
          },
          // {
          //   field: 'calendar',
          //   headerName: 'Calendar',
          //   selector: false,
          //   disableColumnMenu: true,
          //   sortable: false,
          //   flex: 1,
          //   renderCell({ row }) {
          //     return (
          //       <Button
          //         size="small"
          //         startIcon={<Calendar />}
          //         color="inherit"
          //         href={`/waves/${row.id}/overview`}
          //       >
          //         Calendar
          //       </Button>
          //     );
          //   },
          // },
          {
            field: 'spots',
            headerName: 'Spots',
            width: 140,
            sortable: false,
            selector: 'spots { id name variantsAggregate {aggregate{count}}  }',
            renderCell({ row }) {
              return (
                <ColumnItemsMenu
                  text={`${row.spots.length} spots`}
                  icon={<ViewGridOutline />}
                  items={row.spots.map((spot: any) => ({
                    link: `/spots/${spot.id}`,
                    text: `${row.name}: ${spot.name}`,
                    icon: (
                      <Waveform
                        sx={{
                          opacity:
                            spot.variantsAggregate.aggregate.count > 0
                              ? 1
                              : 0.2,
                        }}
                      />
                    ),
                    ...(spot.variantsAggregate.aggregate.count > 0 && {
                      tooltip: 'Audio uploaded',
                    }),
                  }))}
                />
              );
            },
          },
          {
            field: 'firstSpot',
            headerName: 'First spot',
            flex: 1,
            type: 'date',
            selector:
              'firstSpot: spots ( orderBy: { startDate: ASC }, limit: 1 ) { *startDate* }',
            valueGetter({ value }) {
              return value[0]?.startDate ?? null;
            },
            sortable: false,
          },
          {
            field: 'lastSpot',
            headerName: 'Last spot',
            flex: 1,
            type: 'date',
            selector:
              'lastSpot: spots ( orderBy: { endDate: DESC }, limit: 1 ) { *endDate* }',
            valueGetter({ value }) {
              return value[0]?.endDate ?? null;
            },
            sortable: false,
          },
          {
            field: 'planned',
            headerName: 'Planned spots',
            width: 150,
            sortable: false,
            selector:
              'spotsAggregate(where:{isPlanned:{_eq: true}}) { aggregate { count } }',
            valueGetter({ row }) {
              return row.spotsAggregate.aggregate.count;
            },
          },
          { field: 'createdAt', headerName: 'Created at', type: 'date' },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        automaticallyOpenEditPage={false}
        source="wave"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'createdAt', sort: 'desc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        selectProps={{
          filter: {
            stationId: { _eq: selectedStationId },
            campaignId: { _eq: campaignId },
            ...props.selectProps?.filter,
          },
        }}
        formTitle={(isNew) => (isNew ? 'New wave' : 'Edit wave')}
        formDialogProps={{
          ...rest.formDialogProps,
          formSubmitterProps: {
            preSubmit(item) {
              return {
                companyId: user.companyId,
                campaignId,
                stationId: selectedStationId,
                ...item,
              };
            },
          },
        }}
      >
        <FormInput name="name" label="Name" required />
      </DataTableEx>
    );
  },
);
