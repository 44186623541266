import {
  DataTableExRef,
  FormGetter,
  FormInput,
  FormPageLayout,
  HiddenInput,
  SubmitButton,
} from '@kirz/mui-admin';
import { Add, Save } from '@mui/icons-material';
import {
  Box,
  Button,
  InputAdornment,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import dayjs from 'dayjs';
import { ClipboardListOutline, Waves as WavesIcon } from 'mdi-material-ui';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { FormNavigateButton } from 'components/FormNavigateButton';
import { WaveSpotsTable } from 'components/WaveSpotsTable';
import { useAppState } from 'hooks/useAppState';

export function Wave() {
  const { setSelectedStationId } = useAppState();
  const routeParams = useParams() as Record<string, string>;
  const waveId = parseInt(routeParams.id, 10);
  const tableRef = useRef<DataTableExRef>(null);

  return (
    <FormPageLayout
      source="wave"
      hideSaveButton
      breadcrumbs={[
        (item) => ({
          icon: ClipboardListOutline,
          text: item.campaign?.name,
          href: `/campaigns/${item.campaign?.id}`,
        }),
        // (item) => ({
        //   text: 'Waves',
        //   icon: WavesIcon,
        //   href: `/campaigns/${item.campaign?.id}`,
        // }),
        (item) => ({
          text: `${item.name ?? ''}`,
          copyOnClick: true,
        }),
      ]}
      breadcrumbsDeps={['name', 'campaign']}
      defaultRoute="/waves"
      formFetcherProps={{
        onSelection(selections) {
          return [
            ...selections,
            'campaign { id name stationId station { name } }',
            'firstSpot: spots ( orderBy: { startDate: ASC }, limit: 1 ) { startDate }',
            'lastSpot: spots ( orderBy: { endDate: DESC }, limit: 1 ) { endDate }',
            'totalSpots: spotsAggregate { aggregate { count } }',
          ];
        },

        onFetch(item) {
          setSelectedStationId(item?.campaign?.stationId);

          return {
            ...item,
            startDate: item.firstSpot[0]?.startDate,
            endDate: item.lastSpot[0]?.endDate,
            totalSpotsCount: item.totalSpots.aggregate.count,
          };
        },
      }}
    >
      <Grid container alignItems="flex-start" sx={{ pt: 2 }}>
        <HiddenInput name="campaign" formSubmitterValueResolver={null} />
        <HiddenInput
          name="startDate"
          formFetcherValueResolver={null}
          formSubmitterValueResolver={null}
        />
        <HiddenInput
          name="endDate"
          formFetcherValueResolver={null}
          formSubmitterValueResolver={null}
        />

        <FormGetter
          names={['campaign']}
          render={(x) => (
            <>
              {x.campaign ? (
                <FormNavigateButton
                  label="Campaign"
                  url={`/campaigns/${x.campaign?.id}`}
                  text={x.campaign.name}
                  Icon={ClipboardListOutline}
                  color="#001fbf"
                />
              ) : (
                <Grid xs={12} md={3}>
                  <Skeleton width="100%" sx={{ height: 56 }} />
                </Grid>
              )}

              <Grid xs={0} md={8} />
            </>
          )}
        />

        <FormInput
          name="name"
          label="Wave"
          required
          md={3}
          sx={{
            fieldset: {
              borderColor: '#936000',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WavesIcon htmlColor="#936000" />
              </InputAdornment>
            ),
          }}
        />
        <Grid xs={12} md={4}>
          <FormGetter
            names={['startDate', 'endDate']}
            render={(x) => (
              <Stack
                direction="column"
                spacing={0.3}
                sx={{
                  // border: 'thin solid #dcdcdc',
                  borderRadius: 1,
                  // px: 2,
                  // py: 1,
                  mt: 0.4,
                  pl: 2,
                  width: '350px',
                }}
              >
                <Stack direction="row">
                  <Typography sx={{ width: 130 }} fontWeight="500">
                    First spot start:
                  </Typography>
                  <Typography>
                    {x.startDate
                      ? dayjs(x.startDate).format('DD.MM.YYYY')
                      : 'no spots entered yet'}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  <Typography sx={{ width: 130 }} fontWeight="500">
                    Last spot end:
                  </Typography>
                  <Typography>
                    {x.endDate
                      ? dayjs(x.endDate).format('DD.MM.YYYY')
                      : 'no spots entered yet'}
                  </Typography>
                </Stack>
              </Stack>
            )}
          />
        </Grid>
        <Grid
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <SubmitButton
            grid={false}
            variant="contained"
            activateOnDirty
            sx={{ ml: 'auto' }}
            startIcon={<Save />}
          >
            Save changes
          </SubmitButton>
        </Grid>
      </Grid>
      <FormGetter
        names={['campaign', 'id']}
        render={(x) => {
          return (
            <Box
              sx={{
                mt: 5,
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
              }}
            >
              <WaveSpotsTable
                id="wave-spots-table"
                ref={tableRef}
                disableInitialization={!x.campaign}
                campaignId={x.campaign?.id}
                waveId={waveId}
                searchFilter={{
                  slots: {
                    beforeSearch: (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Spots</Typography>
                        <Button
                          startIcon={<Add />}
                          variant="outlined"
                          size="small"
                          sx={{ mx: 2, whiteSpace: 'pre' }}
                          onClick={() => {
                            tableRef.current?.openFormDialog();
                          }}
                        >
                          Add new
                        </Button>
                      </Box>
                    ),
                  },
                  filter(search) {
                    return { _and: [{ name: { _ilike: `%${search}%` } }] };
                  },
                }}
                sortBy={{ field: 'serialNumber', sort: 'asc' }}
                sx={{
                  mt: 1,
                  minHeight: 400,
                  mx: -2,
                  borderColor: 'transparent',
                }}
              />
            </Box>
          );
        }}
      />
    </FormPageLayout>
  );
}
