import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import { SpotType } from 'constants/other';
import { getWeekDay } from 'utils/getWeekDay';

import { HourCheckbox } from './HourCheckbox';
import { SlotCheckbox } from './SlotCheckbox';
import { useSpotOverview } from './SpotOverviewContext';
import { columnWidth, hourModeColumnWidth } from './utils';

type SpotOverviewCalendarWeekHourProps = {
  hour: number;
  date: string;
  skeleton?: boolean;
};

export function SpotOverviewCalendarWeekHour({
  hour,
  date,
}: SpotOverviewCalendarWeekHourProps) {
  const { spot, stationSlotsAtom, daypartSlotsAtom } = useSpotOverview();
  const stationSlots = useAtomValue(stationSlotsAtom);
  const daypartSlots = useAtomValue(daypartSlotsAtom);

  const weekDay = getWeekDay(date);

  const hourDaypartSlots = daypartSlots.filter(
    (x) => x.hour === hour && x.weekDay === weekDay,
  );

  const singleMode = spot.type === SpotType.TIME_RESTRICT;

  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow:
          '0px 2px 4px rgb(31 41 55 / 23%), 0px 4px 6px rgb(100 116 139 / 16%)',
        py: 1,
        borderRadius: 1,
        width: columnWidth,
        position: 'relative',
        ...(!singleMode
          ? {
              pt: '20px',
            }
          : {
              py: 0,
              width: hourModeColumnWidth,
            }),
      }}
    >
      {(() => {
        if (singleMode) {
          const slot = hourDaypartSlots.find((x) =>
            stationSlots.find((y) => y.blockId === x.blockId),
          );

          const block =
            slot && stationSlots.find((y) => y.blockId === slot.blockId);

          return (
            <SlotCheckbox
              date={date}
              minute={block?.minute ?? 0}
              hour={hour}
              weekDay={weekDay}
              daypartSpotId={slot?.daypartSpotId ?? null}
              daypartBlockId={slot?.blockId ?? null}
              singleMode
            />
          );
        }

        return (
          <>
            {stationSlots.map((s, idx) => {
              const slot = hourDaypartSlots.find(
                (x) => x.blockId === s.blockId,
              );

              const allocatedBy =
                spot.disabledSlots[`${date}_${hour}_${s.minute}`];

              return (
                <SlotCheckbox
                  key={idx}
                  date={date}
                  minute={s.minute}
                  hour={hour}
                  weekDay={weekDay as number}
                  daypartSpotId={slot?.daypartSpotId ?? null}
                  daypartBlockId={slot?.blockId ?? null}
                  allocatedBy={allocatedBy}
                />
              );
            })}
            <Box sx={{ position: 'absolute', right: 4, top: -4 }}>
              <HourCheckbox date={date} hour={hour} />
            </Box>
          </>
        );
      })()}
    </Stack>
  );
}
