import { atom, PrimitiveAtom } from 'jotai';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { FetchedSpot, DaypartTimeSlot, StationTimeSlot } from './types';

type SpotOverviewContextType = {
  spot: FetchedSpot;
  stationSlotsAtom: PrimitiveAtom<StationTimeSlot[]>;
  daypartSlotsAtom: PrimitiveAtom<DaypartTimeSlot[]>;
};

export const SpotOverviewContext = createContext<SpotOverviewContextType>(
  {} as any,
);

export function useSpotOverview() {
  return useContext(SpotOverviewContext);
}

export function SpotOverviewProvider({
  spot,
  stationSlots,
  daypartSlots,

  children,
}: PropsWithChildren<{
  spot: FetchedSpot;
  stationSlots: StationTimeSlot[];
  daypartSlots: DaypartTimeSlot[];
}>) {
  const stationSlotsAtom = useMemo(
    () => atom<StationTimeSlot[]>(stationSlots),
    [stationSlots],
  );
  const daypartSlotsAtom = useMemo(
    () => atom<DaypartTimeSlot[]>(daypartSlots),
    [daypartSlots],
  );

  const contextData = useMemo<SpotOverviewContextType>(
    () => ({ spot, stationSlotsAtom, daypartSlotsAtom }),
    [spot, stationSlotsAtom, daypartSlotsAtom],
  );

  return (
    <SpotOverviewContext.Provider value={contextData}>
      {children}
    </SpotOverviewContext.Provider>
  );
}
