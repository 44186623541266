import { ConfigurationContext } from '@kirz/mui-admin';
import { Button, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Alert } from 'mdi-material-ui';
import React, { useContext, useEffect, useState } from 'react';

export function CampaignsAlertArea() {
  const { hasura } = useContext(ConfigurationContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [missingVariantSpot, setMissingVariantSpot] = useState<{
    spotId: number;
    spot: string;
    wave: string;
    campaign: string;
  } | null>(null);

  useEffect(() => {
    (async () => {
      const [result] = await hasura.request({
        type: 'query',
        source: 'spotSelectedSlot',
        where: {
          date: {
            _gte: dayjs().format('YYYY-MM-DD'),
            _lte: dayjs().add(1, 'day').format('YYYY-MM-DD'),
          },
          waveSpot: {
            _not: { variants: { file: { isRemoved: { _eq: false } } } },
          },
        },
        limit: 1,
        selection: [
          'waveSpot { id name wave { id name campaign { id name } } }',
        ],
      });

      if (result) {
        setMissingVariantSpot({
          spotId: result.waveSpot.id,
          spot: result.waveSpot.name,
          wave: result.waveSpot.wave.name,
          campaign: result.waveSpot.wave.campaign.name,
        });
      }

      setIsLoaded(true);
    })();
  }, []);

  return (
    <Stack
      sx={{
        position: 'absolute',
        left: 0,
        top: 6,
        height: 40,
        right: 224,
        borderRadius: 1,
        px: 1.5,
      }}
      direction="row"
      spacing={1.3}
      alignItems="center"
    >
      {!isLoaded ? (
        <>
          <Typography
            variant="subtitle2"
            sx={{ opacity: 0.5, fontWeight: '400' }}
          >
            <Skeleton width={400} />
          </Typography>
        </>
      ) : missingVariantSpot ? (
        <>
          <Alert color="warning" />
          <Typography
            variant="subtitle2"
            sx={{ opacity: 0.8, fontWeight: '400' }}
          >
            Audio missing for {missingVariantSpot.campaign} -{' '}
            {missingVariantSpot.wave} - {missingVariantSpot.spot}
          </Typography>
          <Button size="small" href={`/spots/${missingVariantSpot.spotId}`}>
            Fix issue
          </Button>
        </>
      ) : (
        <>
          {/* <CheckBold color="success" />
          <Typography
            variant="subtitle2"
            sx={{ opacity: 0.5, fontWeight: '400' }}
          >
            No issues found
          </Typography> */}
        </>
      )}
    </Stack>
  );
}
