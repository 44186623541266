import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  AutocompleteInput,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { TextBoxPlusOutline } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { GridNameColumn } from 'components/GridNameColumn';
import { UserContext } from 'contexts/UserContext';
import { HideStationSelector } from 'layouts/HideStationSelector';

export function Dayparts() {
  const { user } = useContext(UserContext);
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        renderCell({ row }) {
          return <GridNameColumn url={`/dayparts/${row.id}`} text={row.name} />;
        },
      },
      {
        field: 'station { *name* }',
        headerName: 'Station',
        type: 'relationship',
        flex: 1,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <HideStationSelector>
      <TablePageLayout
        title="Dayparts"
        actionContent={
          <Button
            sx={{ ml: 5 }}
            variant="contained"
            startIcon={<TextBoxPlusOutline />}
            onClick={async () => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add daypart
          </Button>
        }
      >
        <DataTableEx
          id="dayparts-table"
          ref={tableRef}
          source="daypart"
          columns={columns}
          editPageUrl="/dayparts"
          sortBy={{ field: 'createdAt', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? 'New daypart' : 'Edit daypart')}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by name',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
          formDialogProps={{
            formSubmitterProps: {
              preSubmit(item) {
                return { ...item, companyId: user.companyId };
              },
            },
          }}
        >
          <AutocompleteInput
            name="stationId"
            source="station"
            mode="hasura"
            selection="id name"
            label="Station"
            itemText="name"
            itemValue="id"
            required
          />
          <FormInput name="name" label="Name" required />
        </DataTableEx>
      </TablePageLayout>
    </HideStationSelector>
  );
}
