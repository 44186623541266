export function getSlotId(
  daypartId: number,
  date: string,
  daypartSpotId: number,
  blockId: number,
) {
  return `slot_${daypartId}_${date}_${daypartSpotId}_${blockId}`;
}

export function splitSlotId(slotId: string) {
  const [, daypartId, date, daypartSpotId, blockId] = slotId.split('_');
  return {
    daypartId: parseInt(daypartId, 10),
    date,
    daypartSpotId: parseInt(daypartSpotId, 10),
    blockId: parseInt(blockId, 10),
  };
}

export const hours = [...new Array(24).keys()];

export const firstColumnWidth = 40;
export const columnWidth = 70;
export const lastColumnWidth = 70;
export const hourModeColumnWidth = 60;
