import {
  Button,
  Link,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

export function ColumnItemsMenu({
  text,
  icon,
  items,
}: {
  text: string;
  icon: any;
  items: { icon?: any; text: string; link: string; tooltip?: string }[];
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        disabled={!items.length}
        size="small"
        color="inherit"
        startIcon={icon}
        onClick={handleClick}
      >
        {text}
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {items.map((item, idx) => {
          const Component = MenuItem as any;

          return (
            <Component component={Link} key={idx} to={item.link}>
              {item.icon && (
                <Tooltip title={item.tooltip}>
                  <ListItemIcon sx={{ mr: 1 }}>{item.icon}</ListItemIcon>
                </Tooltip>
              )}
              <ListItemText>{item.text}</ListItemText>
            </Component>
          );
        })}
      </Menu>
    </div>
  );
}
