export function getSlotId(weekDay: number, hour: number, spotBlockId: number) {
  return `slot_${weekDay}_${hour}_${spotBlockId}`;
}

export function splitSlotId(slotId: string) {
  const [, weekDay, hour, spotBlockId] = slotId.split('_');

  return {
    weekDay: parseInt(weekDay, 10),
    hour: parseInt(hour, 10),
    spotBlockId: parseInt(spotBlockId, 10),
  };
}

export const WeekDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
