import { ConfigurationContext, PromiseUtils } from '@kirz/mui-admin';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ArrowLeft, ArrowRight, Home } from 'mdi-material-ui';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import { AppStateContext } from 'contexts/AppStateContext';
import { getWeekDay } from 'utils/getWeekDay';

import { ActiveCampaignsOverviewDialog } from './ActiveCampaignsOverviewDialog';

const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

type ActiveCampaignsCountProps = {
  date: string;
};

function ActiveCampaignsCount({ date }: ActiveCampaignsCountProps) {
  const { selectedStationId } = useContext(AppStateContext);
  const { hasura } = useContext(ConfigurationContext);
  const [count, setCount] = useState<number | null>(null);

  useEffect(() => {
    (async () => {
      const [results] = await Promise.all([
        hasura.request({
          type: 'query',
          source: 'spotSelectedSlot',
          where: {
            spot: { stationId: { _eq: selectedStationId } },
            date: { _eq: date },
          },
          selection: 'waveSpot { campaignId }',
        }),
        PromiseUtils.wait(100),
      ]);

      setCount(results.length);
    })();
  }, [date, selectedStationId]);

  return (
    <Typography sx={{ fontSize: '24px', fontWeight: '500', lineHeight: 1 }}>
      {count == null ? <Skeleton width={20} /> : count}
    </Typography>
  );
}

export function ActiveCampaignsOverview() {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [dayOffset, setDayOffset] = useState(0);
  const today = dayjs().format('YYYY-MM-DD');

  const dates = useMemo(() => {
    const today = dayjs().add(dayOffset, 'days');

    return [
      today.add(-1, 'day').format('YYYY-MM-DD'),
      today.add(0, 'day').format('YYYY-MM-DD'),
      today.add(1, 'day').format('YYYY-MM-DD'),
      today.add(2, 'day').format('YYYY-MM-DD'),
      today.add(3, 'day').format('YYYY-MM-DD'),
      today.add(4, 'day').format('YYYY-MM-DD'),
      today.add(5, 'day').format('YYYY-MM-DD'),
    ];
  }, [dayOffset]);

  return (
    <Stack direction="column" spacing={0.5} sx={{ mx: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body2">Active spots:</Typography>
        <Stack sx={{ ml: 'auto' }} direction="row" spacing={0.5}>
          {!dates.find((x) => x === today) && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                setDayOffset(0);
              }}
            >
              <Home fontSize="small" />
            </IconButton>
          )}

          <IconButton
            size="small"
            onClick={() => {
              setDayOffset((x) => x - 1);
            }}
          >
            <ArrowLeft fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              setDayOffset((x) => x + 1);
            }}
          >
            <ArrowRight fontSize="small" />
          </IconButton>
        </Stack>
      </Box>
      <Stack direction="row" spacing={1}>
        {dates.map((date) => (
          <Stack
            component="div"
            key={date}
            direction="column"
            spacing={0.5}
            alignItems="center"
            justifyContent="center"
            sx={{
              border: 'thin solid #dedede',
              borderRadius: 1,
              px: 1,
              py: 0.5,
              width: 50,
              ':hover': {
                bgcolor: '#e8e7fc',
              },
              ...(date === today && {
                bgcolor: '#5048e5',
                color: 'white',
                ':hover': {
                  bgcolor: '#2822a2',
                },
              }),
              cursor: 'pointer',
              userSelect: 'none',
            }}
            onClick={() => {
              setSelectedDate(date);
            }}
          >
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: 1,
              }}
            >
              {weekDays[getWeekDay(date)]}
            </Typography>
            <Typography
              sx={{
                fontSize: '10px',
                fontWeight: '500',
                lineHeight: 1,
                pb: 0.4,
              }}
            >
              {dayjs(date).format('DD')}/{dayjs(date).format('MM')}
            </Typography>
            <ActiveCampaignsCount date={date} />
          </Stack>
        ))}
      </Stack>
      {selectedDate && (
        <ActiveCampaignsOverviewDialog
          date={selectedDate}
          open={!!selectedDate}
          onClose={() => {
            setSelectedDate(null);
          }}
        />
      )}
    </Stack>
  );
}
