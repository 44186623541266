import { HasuraDataTable, HasuraDataTableColumnDef } from '@kirz/mui-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ArrowLeft, ArrowRight, Close } from 'mdi-material-ui';
import React, { useContext, useMemo, useState } from 'react';

import { AppStateContext } from 'contexts/AppStateContext';

export type ActiveCampaignsOverviewDialogProps = {
  date: string;
  open: boolean;
  onClose: () => void;
};

export function ActiveCampaignsOverviewDialog({
  date,
  open,
  onClose,
}: ActiveCampaignsOverviewDialogProps) {
  const { selectedStationId } = useContext(AppStateContext);
  const [currentDate, setCurrentDate] = useState(date);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        headerName: 'Time',
        field: 'time',
        selector: false,
        sortable: false,
        width: 70,
        disableColumnMenu: true,
        renderCell({ row }) {
          return `${row.spot.hour
            .toString()
            .padStart(2, '0')}:${row.block.minute.toString().padStart(2, '0')}`;
        },
      },
      {
        headerName: 'Spot',
        field: 'spot',
        selector: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell({ row }) {
          return row.waveSpot.name;
        },
      },
      {
        headerName: 'Wave',
        field: 'wave',
        selector: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell({ row }) {
          return row.waveSpot.wave.name;
        },
      },
      {
        headerName: 'Campaign',
        field: 'campaign',
        selector: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell({ row }) {
          return row.waveSpot.campaign.name;
        },
      },
    ],
    [],
  );

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        Day overview
        <IconButton onClick={onClose} sx={{ ml: 'auto' }}>
          <Close />
        </IconButton>{' '}
      </DialogTitle>
      <DialogContent sx={{ overflow: 'visible' }}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ flex: 1, justifyContent: 'center', mt: -5 }}
          alignItems="center"
        >
          <IconButton
            onClick={() => {
              setCurrentDate((x) =>
                dayjs(x).add(-1, 'day').format('YYYY-MM-DD'),
              );
            }}
          >
            <ArrowLeft />
          </IconButton>
          <Typography variant="body1" sx={{ fontSize: 20, fontWeight: '500' }}>
            {dayjs(currentDate).format('DD.MM.YYYY')}
          </Typography>
          <IconButton
            onClick={() => {
              setCurrentDate((x) =>
                dayjs(x).add(1, 'day').format('YYYY-MM-DD'),
              );
            }}
          >
            <ArrowRight />
          </IconButton>
        </Stack>

        <HasuraDataTable
          key={currentDate}
          id="date-overview-spots-table"
          source="spotSelectedSlot"
          columns={columns}
          disableRemovedFilter
          sx={{ mt: 1, minHeight: 363 }}
          // skeletonRowsCount={3}
          selectProps={{
            onSelection(selections) {
              return [
                'block { minute }',
                'spot { hour }',
                'waveSpot { name wave { name } campaign { name } }',
              ];
            },
            filter: {
              date: { _eq: currentDate },
              spot: { stationId: { _eq: selectedStationId } },
            },
            onSort(orderBy) {
              return [{ spot: { hour: 'ASC' } }, { block: { minute: 'ASC' } }];
            },
            onFetch(items) {
              return items.map((x, id) => ({ ...x, id }));
            },
          }}
          pageSizeOptions={[10, 20, 50]}
        />
      </DialogContent>
      <DialogActions>
        <Button sx={{ ml: 'auto' }} onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
