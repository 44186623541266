import { NotificationsContext } from '@kirz/mui-admin';
import { LoadingButton } from '@mui/lab';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { RobotOutline } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { SpotType } from 'constants/other';
import { api } from 'services/api';
import { getDaysArray } from 'utils/getDaysArray';
import { getWeekDay } from 'utils/getWeekDay';

import { FetchedSpot } from './types';
import { getSlotId } from './utils';

export function SuggestCalendarButton({ spot }: { spot: FetchedSpot }) {
  const [isLoading, setIsLoading] = useState(false);
  const { getValues, setValue } = useFormContext();

  const { showAlert } = useContext(NotificationsContext);
  const isHourModeEnabled = spot.type === SpotType.TIME_RESTRICT;

  const handleSuggest = async () => {
    try {
      setIsLoading(true);
      const dates = getDaysArray(spot.startDate, spot.endDate);

      const startDateTimestamp = dayjs(`${spot.startDate}T00:00`).valueOf();

      const allSlots = dates
        .flatMap((date) => {
          const weekDay = getWeekDay(date);
          const weekDaySpots = spot.daypart.spots.filter(
            (x) => x.weekDay === weekDay,
          );

          return weekDaySpots.flatMap((item: any) => {
            const timestmap = dayjs(
              `${date}T${String(item.hour).padStart(2, '0')}:${String(
                item.block.minute,
              ).padStart(2, '0')}`,
            ).valueOf();

            return {
              daypartSpotId: item.id,
              blockId: item.block.id,
              date,
              hour: item.hour,
              minute: item.block.minute,
              minuteOffset: (timestmap - startDateTimestamp) / 1000 / 60,
              timestamp: timestmap,
            };
          });
        })
        .filter((x) => !spot.disabledSlots[`${x.date}_${x.hour}_${x.minute}`]);

      allSlots.sort((a, b) => a.timestamp - b.timestamp);

      let resultSet: Set<number>;
      if (isHourModeEnabled) {
        const hoursToSlotMap = allSlots.reduce((acc, slot) => {
          const key = `${slot.date}_${slot.hour}`;
          if (acc[key]) {
            return acc;
          }
          const timestamp = dayjs(
            `${slot.date}T${String(slot.hour).padStart(2, '0')}:00`,
          ).valueOf();

          acc[key] = {
            slot,
            timestamp,
            minuteOffset: (timestamp - startDateTimestamp) / 1000 / 60,
          };
          return acc;
        }, {} as any);

        const hourSlots = Object.values(hoursToSlotMap) as any[];
        const planned = await api.planSpots({
          SlotsPerDay: spot.numberPerDay,
          Timeslots: hourSlots.map((x: any) => x.minuteOffset),
        });

        resultSet = new Set(
          planned.Timeslots.map(
            (minuteOffset) =>
              hourSlots.find((x: any) => x.minuteOffset === minuteOffset)!.slot
                .timestamp,
          ),
        );
      } else {
        const planned = await api.planSpots({
          SlotsPerDay: spot.numberPerDay,
          Timeslots: allSlots.map((x) => x.minuteOffset),
        });

        resultSet = new Set(
          planned.Timeslots.map(
            (minuteOffset) =>
              allSlots.find((x) => x.minuteOffset === minuteOffset)!.timestamp,
          ),
        );
      }

      const values = getValues();

      for (const slot of allSlots) {
        const slotId = getSlotId(
          spot.daypart.id,
          slot.date,
          slot.daypartSpotId,
          slot.blockId,
        );

        const prevSelected = values[slotId];
        const newSelected = resultSet.has(slot.timestamp);

        if (prevSelected && !newSelected) {
          setValue(slotId, false, { shouldDirty: true });
        }

        if (!prevSelected && newSelected) {
          setValue(slotId, true, { shouldDirty: true });
        }
      }

      setIsLoading(false);

      showAlert('Suggestion applied', 'success');
    } catch {
      showAlert(`Unable to build planning`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <LoadingButton
        variant="contained"
        color="secondary"
        onClick={handleSuggest}
        size="small"
        startIcon={<RobotOutline />}
      >
        Suggest planning
      </LoadingButton>
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
          ml: '0 !important',
        }}
        open={isLoading}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography
            sx={{
              mt: 3,
              textAlign: 'center',
              fontSize: 12,
              lineHeight: 1.2,
              textShadow: '1px 1px 2px #0000008e',
            }}
          >
            Please wait until
            <br />
            we find the best planning
          </Typography>
        </Box>
      </Backdrop>
    </>
  );
}
