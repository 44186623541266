import { SubmitButton } from '@kirz/mui-admin';
import { Save } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState, useTransition } from 'react';

import { SpotType } from 'constants/other';
import { getWeeksBetweenDates } from 'utils/getWeeksArray';

import { ResetCalendarButton } from './ResetCalendarButton';
import { SpotOverviewCalendarWeek } from './SpotOverviewCalendarWeek';
import { SpotOverviewProvider } from './SpotOverviewContext';
import { SuggestCalendarButton } from './SuggestCalendarButton';
import { DaypartTimeSlot, FetchedSpot, StationTimeSlot } from './types';
import {
  columnWidth,
  firstColumnWidth,
  hourModeColumnWidth,
  lastColumnWidth,
} from './utils';

export type SpotOverviewCalendarProps = {
  spot: FetchedSpot;
  stationSlots: StationTimeSlot[];
  daypartSlots: DaypartTimeSlot[];
  originalSlots: Record<string, boolean>;
};

export function SpotOverviewCalendar({
  spot,
  stationSlots,
  daypartSlots,
  originalSlots,
}: SpotOverviewCalendarProps) {
  const containerRef = useRef();
  const [weeks, setWeeks] = useState<{ start: string; end: string }[] | null>(
    null,
  );
  const [selectedWeekIdx, setSelectedWeekIdx] = useState(0);
  const selectedWeek = weeks?.[selectedWeekIdx];
  const [isRendering, startTransition] = useTransition();
  const [renderSchedule, setRenderSchedule] = useState(false);

  const handleWeekChange = (weekIdx: number) => {
    setRenderSchedule(false);
    setSelectedWeekIdx(weekIdx);

    startTransition(() => {
      setRenderSchedule(true);
    });
  };

  useEffect(() => {
    if (containerRef.current) {
      // @ts-ignore
      const rect = containerRef.current.getBoundingClientRect();

      const spacing = 16;
      const numberOfColumns = Math.floor(
        (rect.width - firstColumnWidth - lastColumnWidth - 2 * spacing) /
          ((spot.type === SpotType.TIME_RESTRICT
            ? hourModeColumnWidth
            : columnWidth) +
            spacing),
      );

      setWeeks(
        getWeeksBetweenDates(spot.startDate, spot.endDate, numberOfColumns),
      );

      startTransition(() => {
        setRenderSchedule(true);
      });
    }
  }, []);

  return (
    <Box sx={{ pl: 1, display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Stack direction="row" alignItems="center" spacing={4} sx={{ mb: 0 }}>
        <Stack direction="column" spacing={0.5} sx={{ flex: 1 }}>
          <Typography variant="h6" sx={{ maxWidth: 700 }}>
            [{spot.campaign.name}] {spot.wave.name}
            {'  '}–{'  '}
            {spot.name}
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: 18,
              opacity: selectedWeek ? 1 : 0,
            }}
          >
            {dayjs(selectedWeek?.start).format('DD.MM.YYYY')} -{' '}
            {dayjs(selectedWeek?.end).format('DD.MM.YYYY')}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ ml: 'auto' }}>
          <ResetCalendarButton />
          <SuggestCalendarButton spot={spot} />
          <SubmitButton
            grid={false}
            variant="contained"
            activateOnDirty
            startIcon={<Save />}
          >
            Save planning
          </SubmitButton>
        </Stack>
      </Stack>

      <SpotOverviewProvider
        stationSlots={stationSlots}
        daypartSlots={daypartSlots}
        spot={spot}
      >
        {!weeks ? (
          <Box ref={containerRef} component="div" sx={{ flex: 1 }} />
        ) : (
          isRendering && (
            <SpotOverviewCalendarWeek
              key={`${selectedWeek!.start}_${selectedWeek!.end}_skeleton`}
              week={selectedWeek!}
              selectedWeekIdx={selectedWeekIdx}
              setSelectedWeekIdx={handleWeekChange as any}
              skeleton
            />
          )
        )}
        {renderSchedule && (
          <SpotOverviewCalendarWeek
            key={`${selectedWeek!.start}_${selectedWeek!.end}`}
            week={selectedWeek!}
            selectedWeekIdx={selectedWeekIdx}
            setSelectedWeekIdx={handleWeekChange as any}
          />
        )}
      </SpotOverviewProvider>
    </Box>
  );
}
