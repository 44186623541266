import { Alert, Button, Stack } from '@mui/material';
import { useAtom, getDefaultStore } from 'jotai';
import { Close, ContentCopy } from 'mdi-material-ui';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useDaypartCalendar } from './DaypartCalendarContext';
import { getSlotId, splitSlotId } from './utils';

export function CopySection() {
  const { getValues } = useFormContext();
  const { selectedPositionAtom, copiedBlocksAtom, blocks } =
    useDaypartCalendar();
  const [copiedBlocks, setCopiedBlocks] = useAtom(copiedBlocksAtom);

  return (
    <Stack sx={{ ml: 2 }} spacing={2} alignItems="flex-start">
      <Button
        color="inherit"
        onClick={() => {
          if (!copiedBlocks) {
            const store = getDefaultStore();
            const selectedPosition = store.get(selectedPositionAtom);

            const slotIds = blocks.map((block) =>
              getSlotId(
                selectedPosition.weekDay,
                selectedPosition.hour,
                block.id,
              ),
            );

            const formValues = getValues();

            setCopiedBlocks(
              slotIds
                .filter((slotId) => formValues[slotId])
                .map((slotId) => {
                  return splitSlotId(slotId).spotBlockId;
                }),
            );
          } else {
            setCopiedBlocks(null);
          }
        }}
        variant="outlined"
        startIcon={copiedBlocks ? <Close /> : <ContentCopy />}
      >
        {copiedBlocks ? 'Exit copy mode' : 'Copy selection'}
      </Button>
      {copiedBlocks && (
        <Alert severity="info" sx={{ width: 300 }}>
          Select hours you would like to fill with copied selection
        </Alert>
      )}
    </Stack>
  );
}
