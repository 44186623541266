import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  HiddenInput,
  FormGetter,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Button, Stack, Typography } from '@mui/material';
import React, { useContext, useMemo, useRef } from 'react';

import { StationBlocksTable } from 'components/StationBlocksTable';
import { UserContext } from 'contexts/UserContext';
import { HideStationSelector } from 'layouts/HideStationSelector';

export function Blocks() {
  const { user } = useContext(UserContext);

  const tableRef = useRef<DataTableExRef>(null);
  const blocksTableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Station',
        type: 'relationship',
      },
      {
        field: 'blocks',
        headerName: 'Blocks in minutes + priority',
        selector: 'blocks(orderBy:{minute: ASC}) { minute priority }',
        sortable: false,
        flex: 1,
        valueGetter({ row }) {
          return row.blocks
            .map(
              (x: any) =>
                `${x.minute.toString().padStart(2, '0')}[${x.priority}]`,
            )
            .join(' - ');
        },
      },
    ],
    [],
  );

  return (
    <HideStationSelector>
      <TablePageLayout title="Blocks">
        <DataTableEx
          id="blocks-table"
          ref={tableRef}
          source="station"
          columns={columns}
          deletable={false}
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) =>
            isNew
              ? 'Edit blocks'
              : ((
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h5">Edit blocks</Typography>
                    <Button
                      sx={{ ml: 2 }}
                      startIcon={<Add />}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        blocksTableRef.current?.openFormDialog();
                      }}
                    >
                      Add new
                    </Button>
                  </Stack>
                ) as any)
          }
          selectProps={{
            filter: {
              companyId: { _eq: user.companyId },
            },
          }}
          formDialogProps={{
            maxWidth: 'md',
            onClose() {
              tableRef.current?.reload();
            },
            components: {
              ActionButtons(props) {
                return <Button onClick={props.handleClose}>Close</Button>;
              },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by station',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
            }),
          }}
        >
          <FormGetter
            names={['id', 'companyId']}
            render={({ id: stationId, companyId }) => {
              return (
                <>
                  <StationBlocksTable
                    ref={blocksTableRef}
                    id="station-blocks-table"
                    stationId={stationId}
                    companyId={companyId}
                    sx={{
                      mt: 1,
                      minHeight: 400,
                      mx: 0,
                      borderColor: 'transparent',
                    }}
                  />
                </>
              );
            }}
          />

          <HiddenInput name="id" />
          <HiddenInput name="companyId" />
        </DataTableEx>
      </TablePageLayout>
    </HideStationSelector>
  );
}
