import { TypographyOptions } from '@mui/material/styles/createTypography';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto-mono/500.css';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import { breakpoints } from './breakpoints';

export const typography: TypographyOptions = {
  button: { fontWeight: 600 },
  fontFamily:
    '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  body1: { fontSize: '1rem', fontWeight: 400, lineHeight: 1.5 },
  body2: { fontSize: '0.875rem', fontWeight: 400, lineHeight: 1.57 },
  subtitle1: { fontSize: '1rem', fontWeight: 500, lineHeight: 1.75 },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.57,
  },
  overline: {
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: 2.5,
    textTransform: 'uppercase',
  },
  caption: { fontSize: '0.75rem', fontWeight: 400, lineHeight: 1.66 },
  h1: { fontWeight: 700, fontSize: '3.5rem', lineHeight: 1.375 },
  h2: { fontWeight: 700, fontSize: '3rem', lineHeight: 1.375 },
  h3: { fontWeight: 700, fontSize: '2.25rem', lineHeight: 1.375 },
  h4: {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.375,
    [`@media (min-width: ${breakpoints.values.sm}px)`]: {
      fontSize: '1.8182rem',
    },
    [`@media (min-width: ${breakpoints.values.md}px)`]: {
      fontSize: '2rem',
    },
  },
  h5: { fontWeight: 600, fontSize: '1.5rem', lineHeight: 1.375 },
  h6: { fontWeight: 600, fontSize: '1.125rem', lineHeight: 1.375 },
};
