import { NavigationContext, SelectInput, useNavigate } from '@kirz/mui-admin';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  InputAdornment,
} from '@mui/material';
import { RadioTower } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';

import { TOOLBAR_HEIGHT } from 'constants/theme';
import { useAppState } from 'hooks/useAppState';
import { useSettings } from 'hooks/useSettings';

import { AppBarAccount } from './AppBarAccount';
import { EditProjectDialog } from './EditProjectDialog';

type Props = {
  onDrawerToggleClick: () => void;
};

export function AppBar({ onDrawerToggleClick }: Props) {
  const navigate = useNavigate();
  const { stations, selectedStationId, setSelectedStationId } = useAppState();
  const { reloadSettings } = useSettings();
  const { setNavbarContainer } = useContext(NavigationContext);
  const [isNewProjectDialogOpened, setIsNewProjectDialogOpened] =
    useState(false);

  return (
    <>
      <MuiAppBar
        position="absolute"
        sx={{
          left: { xs: 0 },
          width: { xs: '100%' },
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: 'background.paper',
          height: TOOLBAR_HEIGHT,
          color: (theme) => theme.palette.text.primary,
        }}
      >
        <Toolbar
          sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
        >
          <IconButton
            size="large"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'flex', lg: 'none' } }}
            onClick={onDrawerToggleClick}
          >
            <MenuIcon />
          </IconButton>
          <Box id="station-selector">
            <SelectInput
              sx={{
                '& .MuiFormHelperText-root': {
                  display: 'none',
                },
                minWidth: 250,
                mr: 3,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <RadioTower />
                  </InputAdornment>
                ),
              }}
              helperText={null}
              name="station"
              value={selectedStationId || null}
              onChange={(e) => {
                if (!e.target.value) {
                  return;
                }

                setSelectedStationId(parseInt(e.target.value, 10));
                navigate('/');
              }}
              size="small"
              items={[
                ...stations.map((x) => ({
                  value: x.id,
                  text: x.name,
                })),
              ]}
            />
          </Box>
          <Box
            sx={{
              flex: 1,
              pl: 0,
              pr: 5,
              display: 'flex',
              alignItems: 'center',
            }}
            ref={(ref) => {
              setNavbarContainer(ref as any);
            }}
          />
          <AppBarAccount />
        </Toolbar>
      </MuiAppBar>
      <EditProjectDialog
        open={isNewProjectDialogOpened}
        onSubmit={async (item) => {
          await reloadSettings();
        }}
        onClose={async () => {
          setIsNewProjectDialogOpened(false);
        }}
      />
    </>
  );
}
