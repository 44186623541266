import React, { PropsWithChildren, useEffect } from 'react';

export function HideStationSelector({ children }: PropsWithChildren) {
  useEffect(() => {
    const element = document.querySelector('#station-selector');
    if (!element) {
      return;
    }

    // @ts-ignore
    element.style.display = 'none';

    return () => {
      // @ts-ignore
      element.style.display = 'block';
    };
  }, []);

  return <>{children}</>;
}
