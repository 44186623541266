import { FormGetter } from '@kirz/mui-admin';
import { Checkbox, alpha, useTheme } from '@mui/material';
import { atom, useSetAtom, useAtomValue, getDefaultStore } from 'jotai';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useDaypartCalendar } from './DaypartCalendarContext';
import { getSlotId } from './utils';

type Props = {
  weekDay: number;
  hour: number;
};

export function SpotCheckbox({ weekDay, hour }: Props) {
  const { selectedPositionAtom, blocks, copiedBlocksAtom } =
    useDaypartCalendar();

  const { setValue } = useFormContext();

  const slotIds = blocks.map((block) => getSlotId(weekDay, hour, block.id));

  const store = getDefaultStore();
  const theme = useTheme();
  const setSelectedPosition = useSetAtom(selectedPositionAtom);

  const isSelected = useAtomValue(
    useMemo(
      () =>
        atom(
          (get) =>
            get(selectedPositionAtom).hour === hour &&
            get(selectedPositionAtom).weekDay === weekDay,
        ),
      [hour, weekDay],
    ),
  );

  return (
    <>
      <FormGetter
        names={slotIds}
        render={(values) => {
          const selectedSpots = slotIds.filter((slotId) => values[slotId]);
          const selectionState = !selectedSpots.length
            ? false
            : selectedSpots.length === blocks.length
            ? true
            : 'indeterminate';

          return (
            <Checkbox
              checked={!!selectionState}
              indeterminate={selectionState === 'indeterminate'}
              color={isSelected ? 'primary' : 'default'}
              onClick={() => {
                const copiedBlocks = store.get(copiedBlocksAtom);

                if (copiedBlocks) {
                  if (selectedSpots.length) {
                    slotIds.forEach((slotId) => {
                      setValue(slotId, false, {
                        shouldDirty: true,
                      });
                    });
                  } else {
                    const targetSlotIdsSet = new Set(
                      copiedBlocks.map((blockId) =>
                        getSlotId(weekDay, hour, blockId),
                      ),
                    );

                    slotIds.forEach((slotId) => {
                      setValue(slotId, targetSlotIdsSet.has(slotId), {
                        shouldDirty: true,
                      });
                    });
                  }
                }

                setSelectedPosition({ weekDay, hour });
              }}
              sx={{
                color: isSelected
                  ? theme.palette.primary.main
                  : alpha(theme.palette.text.primary, 0.5),
              }}
            />
          );
        }}
      />
    </>
  );
}
