import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  DateInput,
  AutocompleteInput,
} from '@kirz/mui-admin';
import { Badge, Box, Button } from '@mui/material';
import { Calendar } from 'mdi-material-ui';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { SpotTypes, SpotWeights } from 'constants/other';
import { AppStateContext } from 'contexts/AppStateContext';
import { UserContext } from 'contexts/UserContext';

import { GridNameColumn } from './GridNameColumn';

export const WaveSpotsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      campaignId: number;
      waveId: number;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { user } = useContext(UserContext);
    const { selectedStationId } = useContext(AppStateContext);
    const { campaignId, waveId, filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell({ row }) {
              return (
                <GridNameColumn url={`/spots/${row.id}`} text={row.name} />
              );
            },
          },
          {
            field: 'planning',
            headerName: 'Planning',
            selector: false,
            disableColumnMenu: true,
            sortable: false,
            flex: 1,
            renderCell({ row }) {
              return (
                <Button
                  size="small"
                  startIcon={<Calendar />}
                  color="inherit"
                  href={`/spots/${row.id}/overview`}
                >
                  Planning
                </Button>
              );
            },
          },
          {
            field: 'variants',
            headerName: 'Variants',
            selector: 'variantsAggregate { aggregate {count} }',
            type: 'select',
            width: 100,
            items: SpotWeights,
            renderCell({ row }) {
              return (
                <Badge
                  badgeContent={row.variantsAggregate.aggregate.count}
                  color="primary"
                  sx={{
                    ml: 3,

                    fontSize: '14px',
                    '.MuiBadge-badge': {
                      borderRadius: 1,
                    },
                  }}
                />
              );
            },
          },
          {
            field: 'weight',
            headerName: 'Weight',
            type: 'select',
            width: 150,
            items: SpotWeights,
          },
          {
            field: 'type',
            headerName: 'Type',
            type: 'select',
            width: 150,
            items: SpotTypes,
          },
          {
            field: 'daypart { *name* }',
            headerName: 'Daypart',
            type: 'relationship',
            width: 150,
          },
          {
            field: 'startDate',
            headerName: 'Start date',
            type: 'date',
          },
          {
            field: 'endDate',
            headerName: 'End date',
            type: 'date',
          },
          { field: 'createdAt', headerName: 'Created at', type: 'date' },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="waveSpot"
        automaticallyOpenEditPage={false}
        columns={columns}
        persistStateMode="query"
        editPageUrl="/spots"
        sortBy={rest.sortBy ?? { field: 'createdAt', sort: 'desc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        selectProps={{
          filter: {
            companyId: { _eq: user.companyId },
            stationId: { _eq: selectedStationId },
            campaignId: { _eq: campaignId },
            waveId: { _eq: waveId },
            ...props.selectProps?.filter,
          },
        }}
        formTitle={(isNew) => (isNew ? 'New spot' : 'Edit spot')}
        formDialogProps={{
          ...rest.formDialogProps,
          formSubmitterProps: {
            preSubmit(item) {
              return {
                companyId: user.companyId,
                campaignId,
                stationId: selectedStationId,
                waveId,
                weight: 3,
                ...item,
              };
            },
          },
        }}
      >
        <FormInput name="name" label="Name" required />
        <AutocompleteInput
          name="daypartId"
          label="Daypart"
          required
          mode="hasura"
          source="daypart"
          filter={{
            companyId: { _eq: user.companyId },
            stationId: { _eq: selectedStationId },
          }}
          selection="id name"
          itemText="name"
          itemValue="id"
        />
        <DateInput name="startDate" label="From date" required xs={6} />
        <DateInput name="endDate" label="To date" required xs={6} />
      </DataTableEx>
    );
  },
);
