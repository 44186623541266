import { FormGetter, FormPageLayout } from '@kirz/mui-admin';
import { ClipboardListOutline } from 'mdi-material-ui';
import React from 'react';

import { SpotsCalendar } from 'components/SpotsCalendar';
import { HideStationSelector } from 'layouts/HideStationSelector';

type FetchedItem = {
  id: number;
  waves: {
    spots: {
      id: number;
      name: string;
      daypartId: number;
      slots: {
        date: string;
        daypartId: number;
        spot: { hour: number };
        block: { minute: number };
      }[];
    }[];
  }[];
};

export function CampaignsOverview() {
  return (
    <HideStationSelector>
      <FormPageLayout
        source="campaign"
        breadcrumbs={[
          (item) => ({
            icon: ClipboardListOutline,
            text: 'Campaigns',
            href: `/campaigns`,
          }),
          (item) => ({
            text: `${item.name ?? ''}`,
            href: `/campaigns/${item?.id}`,
          }),
          (item) => ({
            text: `Calendar`,
          }),
        ]}
        breadcrumbsDeps={['name']}
        defaultRoute="/campaigns"
        formFetcherProps={{
          onSelection() {
            return [
              'id',
              'name',
              `waves {
                spots {
                  id
                  name
                  daypartId
                  slots {
                    date
                    daypartId
                    spot {
                      hour
                    }
                    block {
                      minute
                    }
                  }
                }
              }`,
            ];
          },
        }}
      >
        <FormGetter
          names={['campaign', 'id', 'waves']}
          render={(values: FetchedItem) => {
            if (!values.id) {
              return null;
            }

            const spots = values.waves.flatMap((wave) => wave.spots);

            return (
              <SpotsCalendar
                slots={spots.flatMap((spot, spotIndex) => {
                  return spot.slots
                    .filter((x) => x.daypartId === spot.daypartId)
                    .map((slot, idx) => ({
                      date: slot.date,
                      hour: slot.spot.hour,
                      minute: slot.block.minute,
                      name: spot.name,
                      spotId: spot.id,
                      spotIndex,
                    }));
                })}
              />
            );
          }}
        />
      </FormPageLayout>
    </HideStationSelector>
  );
}
