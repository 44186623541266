import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSettings } from 'hooks/useSettings';
import { Station } from 'types/entities';

type AppStateContextType = {
  stations: Station[];
  selectedStationId: number;
  setSelectedStationId: Dispatch<SetStateAction<number>>;
};

export const AppStateContext = createContext<AppStateContextType>({} as any);

export function AppStateProvider({ children }: PropsWithChildren) {
  const { stations } = useSettings();
  const [selectedStationId, setSelectedStationId] = useState(
    parseInt(
      (localStorage.getItem('selectedStationId') === 'null'
        ? null
        : localStorage.getItem('selectedStationId')) ??
        stations?.[0]?.id?.toString(),
      10,
    ),
  );

  useEffect(() => {
    if (
      !selectedStationId ||
      !stations.find((x) => x.id === selectedStationId)
    ) {
      setSelectedStationId(stations[0]?.id);
    }
  }, [selectedStationId, stations]);

  useEffect(() => {
    localStorage.setItem(
      'selectedStationId',
      selectedStationId ? selectedStationId.toString() : 'null',
    );
  }, [selectedStationId]);

  const contextData = useMemo<AppStateContextType>(
    () => ({ stations, selectedStationId, setSelectedStationId }),
    [stations, selectedStationId],
  );

  return (
    <AppStateContext.Provider value={contextData}>
      {children}
    </AppStateContext.Provider>
  );
}
