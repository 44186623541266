import { atom, PrimitiveAtom } from 'jotai';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
} from 'react';

import { FetchedDaypart } from './types';

type DaypartCalendarContextType = {
  daypart: FetchedDaypart;
  blocks: { id: number; minute: number; priority: number }[];
  selectedPositionAtom: PrimitiveAtom<{ weekDay: number; hour: number }>;
  copiedBlocksAtom: PrimitiveAtom<number[] | null>;
};

export const DaypartCalendarContext = createContext<DaypartCalendarContextType>(
  {} as any,
);

export function useDaypartCalendar() {
  return useContext(DaypartCalendarContext);
}

export function DaypartCalendarProvider({
  daypart,
  children,
}: PropsWithChildren<{
  daypart: FetchedDaypart;
}>) {
  const selectedPositionAtom = useMemo(() => atom({ weekDay: 0, hour: 0 }), []);
  const copiedBlocksAtom = useMemo(() => atom<number[] | null>(null), []);

  const blocks = useMemo(() => {
    return daypart.station.blocks;
  }, [daypart]);

  const contextData = useMemo<DaypartCalendarContextType>(
    () => ({ daypart, blocks, selectedPositionAtom, copiedBlocksAtom }),
    [daypart, blocks, selectedPositionAtom, copiedBlocksAtom],
  );

  return (
    <DaypartCalendarContext.Provider value={contextData}>
      {children}
    </DaypartCalendarContext.Provider>
  );
}
